import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

import { GET_ALL_CHATS } from "./chats";
import { LOAD_NOTIFICATIONS } from "./notifications";
import {
  EMPTY_PURCHASES,
  EMPTY_SALES,
  LOAD_PURCHASES,
  LOAD_SALES,
} from "./orders";

const inistialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
};

const auth = createSlice({
  name: "auth",
  initialState: inistialState,
  reducers: {
    REGISTER_SUCCESS(state, action) {
      //get token and user from action payload
      const token = action.payload.token;
      const user = action.payload.user;
      const method = action.payload.user.OAuth
        ? action.payload.user.OAuth
        : "email";

      // Segment Identify users
      process.env.NODE_ENV === "production" &&
        window.analytics.identify(user._id, {
          firstName: user.firstName,
          lastName: user.lastName ? user.lastName : "",
          email: user.email,
          phone: user.phone ? user.phone : "",
        });

      process.env.NODE_ENV === "production" &&
        window.analytics.track("sign_up", { method: method });

      localStorage.setItem("token", token);
      setAuthToken(token);
      //update redux state
      return (state = { isAuthenticated: true, loading: false, token, user });
    },
    REGISTER_FAIL(state, action) {
      //you don't want a wrong token in local storage, always remove it
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["x-auth-token"];

      //update redux state
      return (state = { token: null, isAuthenticated: false, loading: false });
    },
    LOGIN_SUCCESS(state, action) {
      //get token and user from action payload
      const token = action.payload.token;
      const user = action.payload.user;
      const method = action.payload.user.OAuth
        ? action.payload.user.OAuth
        : "email";

      // Segment identify Users
      // process.env.NODE_ENV === "production" &&
      window.analytics.identify(user._id, {
        firstName: user.firstName,
        lastName: user.lastName ? user.lastName : "",
        email: user.email,
        phone: user.phone ? user.phone : "",
      });

      process.env.NODE_ENV === "production" &&
        window.analytics.track("login", { method: method });

      localStorage.setItem("token", token);

      setAuthToken(token);
      //update redux state
      return (state = { isAuthenticated: true, loading: false, token, user });
    },
    LOGIN_FAIL(state, action) {
      //you don't want a wrong token in local storage, always remove it
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["x-auth-token"];

      //update redux state
      return (state = { token: null, isAuthenticated: false, loading: false });
    },
    LOGOUT(state, action) {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["x-auth-token"];

      return (state = { token: null, isAuthenticated: false, loading: false });
    },
    USER_LOADING(state, action) {
      return (state = {
        ...state,
        loading: true,
      });
    },
    USER_LOADED(state, action) {
      return (state = {
        user: action.payload,
        isAuthenticated: true,
        loading: false,
      });
    },
    USER_ERROR(state, action) {
      const user = state.user;
      return (state = {
        isAuthenticated: false,
        loading: false,
        user,
      });
    },

    AUTH_ERROR(state, action) {
      localStorage.removeItem("token");
      return (state = { token: null, isAuthenticated: false, loading: false });
    },
  },
});

export const authActions = auth.actions;
export const REGISTER_SUCCESS = auth.actions.REGISTER_SUCCESS;
export const REGISTER_FAIL = auth.actions.REGISTER_FAIL;
export const LOGIN_SUCCESS = auth.actions.LOGIN_SUCCESS;
export const LOGIN_FAIL = auth.actions.LOGIN_FAIL;
export const LOGOUT = auth.actions.LOGOUT;
export const USER_LOADED = auth.actions.USER_LOADED;
export const AUTH_ERROR = auth.actions.AUTH_ERROR;
export const USER_LOADING = auth.actions.USER_LOADING;
export const USER_ERROR = auth.actions.USER_ERROR;

export const LOAD_USER = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  } else {
    dispatch(USER_ERROR());
    return;
  }
  dispatch(USER_LOADING());
  if (localStorage.cart && !Array.isArray(JSON.parse(localStorage.cart))) {
    localStorage.removeItem("cart");
  }
  try {
    await axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/auth`)
      .then((res) => {
        dispatch(USER_LOADED(res.data));
        dispatch(GET_ALL_CHATS());
        dispatch(LOAD_NOTIFICATIONS());
        dispatch(EMPTY_PURCHASES());
        dispatch(EMPTY_SALES());
        dispatch(LOAD_PURCHASES({ type: "openPurchases", reload: true }));
        dispatch(LOAD_SALES({ type: "openSales", reload: true }));
        dispatch(LOAD_SALES({ type: "openOffers", reload: true }));

        localStorage.removeItem("cart");
      })
      .catch((err) => {
        console.log(err);
        dispatch(USER_ERROR());
      });
  } catch (error) {
    dispatch(USER_ERROR());
  }
};

export default auth;
