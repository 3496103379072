import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { SET_ALERT } from './alert';
import { LOAD_USER } from './auth';

const inistialState = {
	loading: false,
	listings: [],
};
const favorites = createSlice({
	name: 'favorites',
	initialState: inistialState,
	reducers: {
		FAVORITES_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},
		FAVORITES_SUCCESS(state, action) {
			return (state = {
				...state,
				listings: action.payload,
				loading: false,
			});
		},
		FAVORITES_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
		FAVORITES_LOADED(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const FAVORITES_LOADING = favorites.actions.FAVORITES_LOADING;
export const FAVORITES_SUCCESS = favorites.actions.FAVORITES_SUCCESS;
export const FAVORITES_ERROR = favorites.actions.FAVORITES_ERROR;
export const FAVORITES_LOADED = favorites.actions.FAVORITES_LOADED;

export const ADD_TO_FAVORITES = (listingId) => async (dispatch, getState) => {
	const { auth } = await getState();

	if (!auth.isAuthenticated) {
		dispatch(
			SET_ALERT({
				msg: 'You have to be logged in first',
				type: 'danger',
			})
		);
		return;
	}

	dispatch(FAVORITES_LOADING());

	for (let item of auth.user.favorites) {
		if (item._id === listingId) {
			dispatch(
				SET_ALERT({
					msg: 'Item is already present in your favorites',
					type: 'danger',
				})
			);
			dispatch(FAVORITES_ERROR());
			return;
		}
	}
	let userFavorites = [];
	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/cart/favorites/${listingId}`)
		.then((res) => {
			dispatch(
				SET_ALERT({
					msg: `Added to favorites`,
					type: 'success',
				})
			);
			dispatch(LOAD_USER());
			userFavorites = res.data;
		})
		.catch((error) => {
			dispatch(FAVORITES_ERROR());
		});
	let segmentFavorites = [];
	for (let listing of userFavorites) {
		if (listing.status === 'active') {
			segmentFavorites.push({
				item_id: listing._id,
				item_name: listing.title,
				item_estRetailPrice:
					listing.estRetailPrice &&
					listing.estRetailPrice !== 'null' &&
					listing.estRetailPrice !== 'undefined'
						? listing.estRetailPrice
						: null,
				item_image: listing.images[0],
				item_brand: listing.brand
					? listing.brand
					: listing.velouAttributes?.manufacturer?.length > 0
					? listing.velouAttributes?.manufacturer[0]
							?.replace(/_/g, ' ')
							?.split(' ')
							?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
							?.join(' ')
					: listing.velouAttributes?.brands?.length > 0
					? listing.velouAttributes?.brands[0]
							?.replace(/_/g, ' ')
							?.split(' ')
							?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
							?.join(' ')
					: '',
				item_category: listing.newSchema.categories[0],
				item_category2: listing.newSchema.subCategories[0]
					? listing.newSchema.subCategories[0]
					: '',
				item_category3: listing.newSchema.types[0]
					? listing.newSchema.types[0]
					: '',
				item_price: listing.price,
			});
		}
	}

	process.env.NODE_ENV === 'production' &&
		window.analytics.identify(auth.user._id, {
			favorites:
				segmentFavorites.length > 0 ? JSON.stringify(segmentFavorites) : '',
			firstName: auth.user.firstName,
			lastName: auth.user.lastName ? auth.user.lastName : '',
			phone: auth.user.phone ? auth.user.phone : '',

			email: auth.user.email,
		});
};

export const REMOVE_FROM_FAVORITES =
	(listingId) => async (dispatch, getState) => {
		const { auth } = await getState();

		dispatch(FAVORITES_LOADING());

		let userFavorites = [];

		await axios
			.post(
				`${process.env.REACT_APP_SERVER_API}/api/cart/favorites/remove/${listingId}`
			)
			.then(async (res) => {
				dispatch(LOAD_USER());
				userFavorites = res.data;
			})
			.then((res) => {
				dispatch(
					SET_ALERT({
						msg: `Removed from favorites`,
						type: 'danger',
					})
				);
			})
			.catch((error) => {
				dispatch(FAVORITES_ERROR());
			});
		let segmentFavorites = [];
		for (let listing of userFavorites) {
			if (listing.status === 'active') {
				segmentFavorites.push({
					item_id: listing._id,
					item_name: listing.title,
					item_estRetailPrice:
						listing.estRetailPrice &&
						listing.estRetailPrice !== 'null' &&
						listing.estRetailPrice !== 'undefined'
							? listing.estRetailPrice
							: null,
					item_image: listing.images[0],
					item_brand: listing.brand
						? listing.brand
						: listing.velouAttributes?.manufacturer?.length > 0
						? listing.velouAttributes?.manufacturer[0]
								?.replace(/_/g, ' ')
								?.split(' ')
								?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								?.join(' ')
						: listing.velouAttributes?.brands?.length > 0
						? listing.velouAttributes?.brands[0]
								?.replace(/_/g, ' ')
								?.split(' ')
								?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								?.join(' ')
						: '',
					item_category: listing.newSchema.categories[0],
					item_category2: listing.newSchema.subCategories[0]
						? listing.newSchema.subCategories[0]
						: '',
					item_category3: listing.newSchema.types[0]
						? listing.newSchema.types[0]
						: '',
					item_price: listing.price,
				});
			}
		}

		process.env.NODE_ENV === 'production' &&
			window.analytics.identify(auth.user._id, {
				favorites:
					segmentFavorites.length > 0 ? JSON.stringify(segmentFavorites) : '',
				firstName: auth.user.firstName,
				lastName: auth.user.lastName ? auth.user.lastName : '',
				phone: auth.user.phone ? auth.user.phone : '',

				email: auth.user.email,
			});
	};

export default favorites;
