import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	openPurchases: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedPurchases: {
		loading: false,
		loaded: false,
		orders: [],
	},
	openOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},
};

const purchases = createSlice({
	name: 'purchases',
	initialState,
	reducers: {
		PURCHASES_LOADING(state, action) {
			let { tab } = action.payload;
			return (state = { ...state, [tab]: { ...state[tab], loading: true } });
		},

		PURCHASES_LOADED(state, action) {
			let { tab, orders } = action.payload;

			return (state = {
				...state,
				[tab]: { loading: false, loaded: true, orders },
			});
		},

		PURCHASES_ERROR(state, action) {
			let { tab } = action.payload;
			return (state = { ...state, [tab]: { loading: false, loaded: true } });
		},

		EMPTY_PURCHASES(state) {
			return (state = initialState);
		},
	},
});

export const PURCHASES_LOADING = purchases.actions.PURCHASES_LOADING;
export const PURCHASES_LOADED = purchases.actions.PURCHASES_LOADED;
export const PURCHASES_ERROR = purchases.actions.PURCHASES_ERROR;
export const EMPTY_PURCHASES = purchases.actions.EMPTY_PURCHASES;

export const LOAD_PURCHASES =
	({ type, reload }) =>
	async (dispatch, getState) => {
		const { purchases } = await getState();

		if (purchases[type]?.loaded && !reload) {
			return;
		}

		dispatch(PURCHASES_LOADING({ tab: type }));

		// return;
		await axios
			.post(`${process.env.REACT_APP_SERVER_API}/api/chat/purchases`, {
				type,
			})
			.then(async (res) => {
				console.log(res.data);
				dispatch(PURCHASES_LOADED({ tab: type, orders: res.data }));
			})
			.catch(async (error) => {
				dispatch(PURCHASES_ERROR({ tab: type }));
			});
	};

const initialSalesState = {
	openSales: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedSales: {
		loading: false,
		loaded: false,
		orders: [],
	},
	openOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},
};

const sales = createSlice({
	name: 'sales',
	initialState: initialSalesState,
	reducers: {
		SALES_LOADING(state, action) {
			let { tab } = action.payload;
			return (state = { ...state, [tab]: { ...state[tab], loading: true } });
		},

		SALES_LOADED(state, action) {
			let { tab, orders } = action.payload;

			return (state = {
				...state,
				[tab]: { loading: false, loaded: true, orders },
			});
		},

		SALES_ERROR(state, action) {
			let { tab } = action.payload;
			return (state = { ...state, [tab]: { loading: false, loaded: true } });
		},

		EMPTY_SALES(state) {
			return (state = initialState);
		},
	},
});

export const SALES_LOADING = sales.actions.SALES_LOADING;
export const SALES_LOADED = sales.actions.SALES_LOADED;
export const SALES_ERROR = sales.actions.SALES_ERROR;
export const EMPTY_SALES = sales.actions.EMPTY_SALES;

export const LOAD_SALES =
	({ type, reload }) =>
	async (dispatch, getState) => {
		const { sales } = await getState();

		if (sales[type]?.loaded && !reload) {
			return;
		}

		dispatch(SALES_LOADING({ tab: type }));

		// return;
		await axios
			.post(`${process.env.REACT_APP_SERVER_API}/api/chat/sales`, {
				type,
			})
			.then(async (res) => {
				console.log(res.data);
				dispatch(SALES_LOADED({ tab: type, orders: res.data }));
			})
			.catch(async (error) => {
				console.log(error);
				dispatch(SALES_ERROR({ tab: type }));
				// dispatch(SET_ALERT({ msg: 'Error loading sales', type: 'danger' }));
			});
	};

export default { purchases, sales };
