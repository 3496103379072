import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SET_ALERT } from './alert';

const inistialState = {
	loading: false,
	users: [],
};
const users = createSlice({
	name: 'users',
	initialState: inistialState,
	reducers: {
		USERS_LOADING(state, action) {
			return (state = { ...state, users: true });
		},
		USERS_SUCCESS(state, action) {
			return (state = {
				users: action.payload,
				loading: false,
			});
		},
		USERS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const USERS_LOADING = users.actions.USERS_LOADING;
export const USERS_SUCCESS = users.actions.USERS_SUCCESS;
export const USERS_ERROR = users.actions.USERS_ERROR;

export const DELETE_USER = (props) => async (dispatch) => {
	dispatch(USERS_LOADING());
	await axios
		.delete(`${process.env.REACT_APP_SERVER_API}/api/users/${props}`)
		.then(async (res) => {
			dispatch(USERS_SUCCESS(res.data));

			dispatch(
				SET_ALERT({
					msg: `Your account has been deleted with all it's listings!`,
					type: 'success',
				})
			);
		})
		.catch((error) => {
			dispatch(USERS_ERROR());
		});
};

export default users;
