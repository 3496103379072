import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inistialState = {
	loading: false,
	listings: [],
	moveouts: [],
};
const soldListings = createSlice({
	name: 'soldListings',
	initialState: inistialState,
	reducers: {
		SOLD_LISTINGS_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},

		SOLD_LISTINGS_SUCCESS(state, action) {
			return (state = {
				listings: action.payload.listings,
				moveouts: action.payload.moveouts,
				loading: false,
			});
		},

		SOLD_LISTINGS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
		SOLD_LISTINGS_LOADED(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const SOLD_LISTINGS_LOADING = soldListings.actions.SOLD_LISTINGS_LOADING;
export const SOLD_LISTINGS_SUCCESS = soldListings.actions.SOLD_LISTINGS_SUCCESS;
export const SOLD_LISTINGS_ERROR = soldListings.actions.SOLD_LISTINGS_ERROR;
export const SOLD_LISTINGS_LOADED = soldListings.actions.SOLD_LISTINGS_LOADED;

export const LOAD_SOLD_LISTINGS = () => async (dispatch) => {
	dispatch(SOLD_LISTINGS_LOADING());

	// return;
	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/listings/sold`)
		.then(async (res) => {
			dispatch(SOLD_LISTINGS_SUCCESS(res.data));
		})
		.catch(async (error) => {
			dispatch(SOLD_LISTINGS_ERROR());
		});
};

export default soldListings;
