import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SET_ALERT } from './alert';

const inistialState = {
	loading: false,
	articles: [],
};
const articles = createSlice({
	name: 'articles',
	initialState: inistialState,
	reducers: {
		ARTICLES_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},
		ARTICLES_SUCCESS(state, action) {
			return (state = {
				articles: action.payload,
				loading: false,
			});
		},

		ARTICLES_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
		ARTICLES_LOADED(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const ARTICLES_LOADING = articles.actions.ARTICLES_LOADING;
export const ARTICLES_ERROR = articles.actions.ARTICLES_ERROR;
export const ARTICLES_LOADED = articles.actions.ARTICLES_LOADED;
export const ARTICLES_SUCCESS = articles.actions.ARTICLES_SUCCESS;

export const ADD_ARTICLE = (content) => async (dispatch) => {
	dispatch(ARTICLES_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/blogs/new`, { content })
		.then(() => {
			dispatch(LOAD_ARTICLES());
			dispatch(
				SET_ALERT({
					msg: `Your article has been posted!`,
					type: 'success',
				})
			);
		})
		.catch(() => {
			dispatch(ARTICLES_ERROR());
			dispatch(SET_ALERT({ msg: 'Server error', type: 'danger' }));
		});
};

export const REMOVE_ARTICLE = (articleId) => async (dispatch) => {
	dispatch(ARTICLES_LOADING());

	await axios
		.delete(`${process.env.REACT_APP_SERVER_API}/api/blogs/${articleId}`)
		.then(() => {
			dispatch(LOAD_ARTICLES());
			dispatch(
				SET_ALERT({
					msg: `Article deleted`,
					type: 'success',
				})
			);
		})
		.catch(() => {
			dispatch(ARTICLES_ERROR());
			dispatch(SET_ALERT({ msg: 'Server error', type: 'danger' }));
		});
};

export const LOAD_ARTICLES = () => async (dispatch) => {
	dispatch(ARTICLES_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/blogs`)
		.then((res) => {
			dispatch(ARTICLES_SUCCESS(res.data));
		})
		.catch(() => {
			dispatch(ARTICLES_ERROR());
			dispatch(SET_ALERT({ msg: 'Server error', type: 'danger' }));
		});
};

export default articles;
