import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// import lists from '../../utils/listings2.txt';

const inistialState = {
	loading: false,
	requests: [],
};

const requests = createSlice({
	name: 'requests',
	initialState: inistialState,
	reducers: {
		REQUESTS_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},

		REQUESTS_SUCCESS(state, action) {
			return (state = {
				requests: action.payload,

				loading: false,
			});
		},

		REQUESTS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const REQUESTS_LOADING = requests.actions.REQUESTS_LOADING;

export const REQUESTS_SUCCESS = requests.actions.REQUESTS_SUCCESS;

export const REQUESTS_ERROR = requests.actions.REQUESTS_ERROR;

export const LOAD_REQUESTS = () => async (dispatch) => {
	dispatch(REQUESTS_LOADING());

	// return;
	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/listings/user-listings`)
		.then(async (res) => {
			dispatch(REQUESTS_SUCCESS(res.data));
		})
		.catch(async (error) => {
			dispatch(REQUESTS_ERROR());
		});
};

export default requests;
