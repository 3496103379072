import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	allProducts: {},
	allPage: { current: 1, loaded: 1, total: 1 },

	activeProducts: {},
	activePage: { current: 1, loaded: 1, total: 1 },

	soldProducts: {},
	soldPage: { current: 1, loaded: 1, total: 1 },

	inactiveProducts: {},
	inactivePage: { current: 1, loaded: 1, total: 1 },

	searchProducts: {},
	searchPage: { current: 1, loaded: 1, total: 1 },
};

const dashboardProducts = createSlice({
	name: 'dashboardProducts',
	initialState,
	reducers: {
		SAVE_DASHBOARD_ALLPRODUCTS(state, action) {
			const { products, currentPage, totalPages } = action.payload;
			state.allProducts[currentPage] = products;
			state.allPage.total = totalPages;
			state.allPage.current = currentPage;
			if (currentPage > state.allPage.loaded) {
				state.allPage.loaded = currentPage;
			}
		},
		SAVE_DASHBOARD_ACTIVEPRODUCTS(state, action) {
			const { products, currentPage, totalPages } = action.payload;
			state.activeProducts[currentPage] = products;
			state.activePage.total = totalPages;
			state.activePage.current = currentPage;
			if (currentPage > state.activePage.loaded) {
				state.activePage.loaded = currentPage;
			}
		},
		SAVE_DASHBOARD_SOLDPRODUCTS(state, action) {
			const { products, currentPage, totalPages } = action.payload;
			state.soldProducts[currentPage] = products;
			state.soldPage.total = totalPages;
			state.soldPage.current = currentPage;
			if (currentPage > state.soldPage.loaded) {
				state.soldPage.loaded = currentPage;
			}
		},
		SAVE_DASHBOARD_INACTIVEPRODUCTS(state, action) {
			const { products, currentPage, totalPages } = action.payload;
			state.inactiveProducts[currentPage] = products;
			state.inactivePage.total = totalPages;
			state.inactivePage.current = currentPage;
			if (currentPage > state.inactivePage.loaded) {
				state.inactivePage.loaded = currentPage;
			}
		},
		SAVE_DASHBOARD_SEARCHPRODUCTS(state, action) {
			const { products, currentPage, totalPages } = action.payload;
			state.searchProducts[currentPage] = products;
			state.searchPage.total = totalPages;
			state.searchPage.current = currentPage;
			if (currentPage > state.searchPage.loaded) {
				state.searchPage.loaded = currentPage;
			}
		},
		EMPTY_DASHBOARD_SEARCHPRODUCTS(state, action) {
			state.searchProducts = {};
			state.searchPage.total = 1;
			state.searchPage.current = 1;
		},

		SET_ALLPRODUCTS_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.allPage.current = currentPage;
			if (currentPage > state.allPage.loaded) {
				state.allPage.loaded = currentPage;
			}
		},
		SET_ACTIVEPRODUCTS_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.activePage.current = currentPage;
			if (currentPage > state.activePage.loaded) {
				state.activePage.loaded = currentPage;
			}
		},
		SET_SOLDPRODUCTS_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.soldPage.current = currentPage;
			if (currentPage > state.soldPage.loaded) {
				state.soldPage.loaded = currentPage;
			}
		},
		SET_INACTIVEPRODUCTS_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.inactivePage.current = currentPage;
			if (currentPage > state.inactivePage.loaded) {
				state.inactivePage.loaded = currentPage;
			}
		},
		SET_SEARCHPRODUCTS_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.searchPage.current = currentPage;
			if (currentPage > state.searchPage.loaded) {
				state.searchPage.loaded = currentPage;
			}
		},
	},
});

export const { SAVE_DASHBOARD_ALLPRODUCTS } = dashboardProducts.actions;
export const { SAVE_DASHBOARD_ACTIVEPRODUCTS } = dashboardProducts.actions;
export const { SAVE_DASHBOARD_SOLDPRODUCTS } = dashboardProducts.actions;
export const { SAVE_DASHBOARD_INACTIVEPRODUCTS } = dashboardProducts.actions;
export const { SAVE_DASHBOARD_SEARCHPRODUCTS } = dashboardProducts.actions;
export const { EMPTY_DASHBOARD_SEARCHPRODUCTS } = dashboardProducts.actions;

export const { SET_ALLPRODUCTS_PAGE } = dashboardProducts.actions;
export const { SET_ACTIVEPRODUCTS_PAGE } = dashboardProducts.actions;
export const { SET_SOLDPRODUCTS_PAGE } = dashboardProducts.actions;
export const { SET_INACTIVEPRODUCTS_PAGE } = dashboardProducts.actions;
export const { SET_SEARCHPRODUCTS_PAGE } = dashboardProducts.actions;
export default dashboardProducts;
