import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BrandInfoText } from "../../utils/BrandInfoText";

const BrandLandingHelmet = () => {
  const { collection } = useParams();
  const [displayInfo, setDisplayInfo] = useState();

  useEffect(() => {
    const brandInfo = BrandInfoText.find(
      (brand) =>
        brand?.name?.replace(/ /g, "-").replace(/&/g, "and").toLowerCase() ===
        collection.toLowerCase()
    );

    setDisplayInfo(brandInfo);
  }, [collection]);

  return (
    <Helmet>
      {displayInfo && (
        <>
          <title data-rh="true">
            {displayInfo?.name} Furniture | Kashew | Used Designer Furniture
          </title>
          <meta
            name="description"
            content={`Discover used ${displayInfo?.name} furniture – up to 80% off retail. ✓ Available immediately ✓ White glove, nationwide delivery ✓ 100% Satisfaction guarantee`}
            data-rh="true"
          />
          <meta
            property="og:title"
            content={`${displayInfo?.name} Furniture | Kashew | Used Designer Furniture`}
            data-rh="true"
          />
          <meta
            property="og:description"
            content={`Discover used ${displayInfo?.name} furniture – up to 80% off retail. ✓ Available immediately ✓ White glove, nationwide delivery ✓ 100% Satisfaction guarantee. `}
            data-rh="true"
          />
          <meta
            property="og:image"
            content={displayInfo?.hero_img}
            data-rh="true"
          />

          <meta
            property="og:image:alt"
            content={`${displayInfo?.name} Furniture | Kashew | Used Designer Furniture | Sofas | Chairs | Tables | Beds | Lighting | Decor`}
            data-rh="true"
          />

          <meta
            property="og:url"
            content={`https://kashew.com/pages/${collection}`}
            data-rh="true"
          />

          <meta
            property="canonical"
            content={`https://kashew.com/pages/${collection}`}
            data-rh="true"
          />
        </>
      )}
    </Helmet>
  );
};

export default BrandLandingHelmet;
