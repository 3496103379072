export const BrandInfoText = [
  {
    name: "West Elm",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/west_elm_logo.svg",
    hero_text:
      "West Elm is a popular home decor and furniture brand known for its modern and stylish designs. Based in Brooklyn, New York, it appeals to urban customers with its focus on sustainability and fair trade practices. West Elm offers a range of products including furniture, bedding, rugs, and lighting, emphasizing organic, handcrafted, and sustainably sourced materials.",
    facts: [
      { key: "Founded", value: "2002" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale with artisanal collections" },
      {
        key: "Features",
        value: "Sustainable and organic materials, modern & mid century design",
      },
      {
        key: "Iconic Products",
        value: "Mid-Century Modern Furniture, Andes Sofa",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/west_elm.jpeg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/west_elm.jpg",

    sections: [
      {
        title: "Commitment to Sustainability",
        text: "At West Elm, sustainability is at the core of every design. From the reclaimed wood in their tables to the organic cotton in their drapes, each material is chosen with the environment in mind. West Elm was one of the first home retailers to embrace Fair Trade practices, ensuring that the hands crafting your furniture are paid fairly and work in safe conditions.\n\nThis eco-conscious philosophy extends to every corner of their production, striving not only to reduce the carbon footprint but also to improve the communities involved in the manufacturing process.",
      },
      {
        title: "Fostering Local Artistry and Community",
        text: "West Elm's commitment to community and creativity is evident in their collaboration with local artisans. By partnering with skilled craftsmen and artists, they bring distinctive, locally inspired pieces to the global market. Each collaboration helps sustain traditional crafts and promotes economic stability in local communities.\n\nMoreover, through initiatives like West Elm LOCAL, the brand integrates products made by local artisans into their stores, enriching their product lineup while supporting the cultural and economic vitality of the communities they serve.",
      },
    ],
    link: "https://www.westelm.com/",
  },
  {
    name: "Thomasville",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/thomasville.svg",
    hero_text:
      "Thomasville is an esteemed furniture company with a rich history dating back to 1904 in Thomasville, North Carolina. Originally famed for its fine-quality chairs, it now offers a wide assortment of furniture and accessories for every room. Thomasville is known for its classic American designs and durable craftsmanship.",
    facts: [
      { key: "Founded", value: "1904" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Mainly United States" },
      { key: "Manufacturing", value: "Traditional craftsmanship" },
      {
        key: "Features",
        value: "Classic American designs, high-quality wood products",
      },
      {
        key: "Iconic Products",
        value: "Ernest Hemingway Collection, Bridges 2.0",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/thomasville_hero.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/thomasville.webp",
    sections: [
      {
        title: "Heritage of Craftsmanship",
        text: "Founded in 1904 in Thomasville, North Carolina, Thomasville began as a chair company and quickly earned a reputation for the fine quality of its furniture. Over the decades, Thomasville has preserved this tradition, emphasizing meticulous craftsmanship and timeless designs. Today, their furniture is synonymous with American classic styles, reflecting a deep appreciation for woodworking artistry and detail.\n\nTheir commitment to craftsmanship extends through the careful selection of materials, the artisan techniques employed, and the detailed finishes that characterize each piece, ensuring that they not only look exquisite but are also built to last.",
      },
      {
        title: "Innovation in Design",
        text: "As Thomasville has evolved, so has its approach to furniture design, seamlessly integrating technology with classic aesthetics to meet modern needs. Innovations such as ergonomic features in seating, sustainable material sourcing, and advanced manufacturing processes highlight their adaptation to contemporary lifestyles.\n\nMoreover, Thomasville has embraced the digital age, offering customers virtual room planning and augmented reality tools to visualize furniture in their homes before purchasing. This forward-thinking approach not only enhances the customer experience but also underlines Thomasville's role in shaping the future of furniture design.",
      },
    ],
    link: "https://www.thomasville.com/",
  },
  {
    name: "Room & Board",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/room_and_board.svg",
    hero_text:
      "Room & Board is a furniture and home furnishings company emphasizing modern, minimalist designs made primarily in the USA. Their products, ranging from outdoor furniture to office setups, are praised for their durability and timeless style. The brand focuses on sustainability and craftsmanship, using materials like reclaimed wood and recycled steel.",
    facts: [
      { key: "Founded", value: "1980" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "United States" },
      { key: "Manufacturing", value: "Artisanal manufacturing" },
      {
        key: "Features",
        value: "Minimalist design, sustainability, extensive custom options",
      },
      {
        key: "Iconic Products",
        value: "Jasper Sofa, Adams Extension Tables",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/room_and_board.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/room_and_board.jpg",
    sections: [
      {
        title: "American Craftsmanship and Design",
        text: "Room & Board is deeply committed to American craftsmanship, sourcing over 90% of their products from within the United States. By partnering with small, family-owned businesses that prioritize quality and ethical practices, they ensure that each piece not only supports local economies but is also built to last. This approach fosters a collection of furniture that embodies durability and a minimalist aesthetic, appealing to a sense of timeless style.\n\nTheir dedication to domestic manufacturing also means that Room & Board can closely monitor production processes, maintaining high standards of craftsmanship and material selection. This close relationship between design and manufacturing results in a uniquely cohesive collection of furniture.",
      },
      {
        title: "Sustainability at the Core",
        text: "Sustainability is integral to Room & Board's operations. The company emphasizes the use of sustainable materials such as reclaimed wood and recycled steel, significantly reducing their environmental footprint. These materials are not only eco-friendly but also add distinctive character and robustness to their furniture pieces.\n\nBeyond material selection, Room & Board implements energy-efficient manufacturing techniques and waste reduction strategies. Their proactive environmental initiatives extend to participating in policy advocacy, aiming to protect and sustain natural resources essential to their craft. This comprehensive commitment to sustainability showcases Room & Board’s efforts to produce eco-conscious products that align with their customers' values.",
      },
    ],
    link: "https://www.roomandboard.com/",
  },
  {
    name: "Restoration Hardware",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/restoration_hardware.svg",
    hero_text:
      "Restoration Hardware is a luxury brand in the home furnishing marketplace, offering furniture, lighting, textiles, bathware, and decor. Known for its updated classics and timeless styles, the company redefines traditional designs through a contemporary lens. Its aesthetic is often described as upscale and sophisticated.",
    facts: [
      { key: "Founded", value: "1979" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      {
        key: "Manufacturing",
        value: "Large scale production with some artisanal items",
      },
      {
        key: "Features",
        value:
          "Luxury furnishings, high-end materials, and detailed craftsmanship",
      },
      {
        key: "Iconic Products",
        value: "Cloud Sofa, French Contemporary Dining Table",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/restoration_hardware.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/rh.jpg",
    sections: [
      {
        title: "Elevating Traditional Elegance",
        text: "Restoration Hardware reimagines traditional design through a modern lens, offering an array of furniture, lighting, and decor that blends classic aesthetics with contemporary elegance. Known for transforming updated classics into luxurious, timeless pieces, their collections cater to sophisticated tastes and upscale environments.\n\nEvery product, from the plush Cloud Sofa to the sleek French Contemporary Dining Table, is crafted with an eye for timeless design and an emphasis on comfort and style. This approach not only refines each item but ensures it transcends trends, making each purchase a lasting investment.",
      },
      {
        title: "Commitment to Quality and Craftsmanship",
        text: "Restoration Hardware's commitment to quality is evident in their selection of high-end materials and meticulous craftsmanship. The brand employs a mix of Large scale production techniques and artisanal craftsmanship, enabling them to produce luxurious furnishings at scale while maintaining a high standard of quality.\n\nMaterials are sourced globally to ensure premium quality, from sumptuous leathers to rich, durable woods and metals, which are then transformed into exquisite furniture pieces by skilled artisans. This dedication to craftsmanship not only highlights their luxurious approach but also ensures that each piece is as durable as it is beautiful.",
      },
    ],

    link: "https://www.restorationhardware.com/",
  },
  {
    name: "Pottery Barn",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/pottery_barn.svg",
    hero_text:
      "Pottery Barn is a well-known retailer offering upscale home furnishings, including furniture, bedding, and decor. Established in 1949, the brand has grown into a symbol of comfortable, well-designed lifestyles, widely recognized for its cozy, country-inspired aesthetics.",
    facts: [
      { key: "Founded", value: "1949" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value:
          "Comfortable, family-friendly designs, wide range of home products",
      },
      {
        key: "Iconic Products",
        value: "Pearce Sofa, Bedford Collection",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/pb.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/pottery_barn.jpg",
    sections: [
      {
        title: "A Heritage of Comfort and Style",
        text: "Since its inception in 1949, Pottery Barn has been synonymous with comfortable, stylish home living. The brand is celebrated for its cozy, country-inspired designs that make houses feel like homes. Each piece, from the plush Pearce Sofa to the versatile Bedford Collection, is designed with both beauty and functionality in mind, ensuring they fit seamlessly into everyday life.\n\nThis commitment to style and comfort is central to Pottery Barn’s philosophy, making their products a favorite for families looking to create inviting, livable spaces.",
      },
      {
        title: "Dedicated to Quality and Sustainability",
        text: "Pottery Barn's approach to production combines global sourcing with rigorous standards for quality and sustainability. The brand emphasizes the use of durable materials to create furniture that is not only long-lasting but also environmentally friendly. Pottery Barn’s commitment to sustainability is reflected in their extensive use of organic materials and non-toxic finishes, making their products safer for customers and the planet.\n\nMoreover, Pottery Barn continuously seeks to improve their environmental impact by implementing practices that reduce waste and enhance resource efficiency across all areas of their manufacturing and supply chain.",
      },
    ],
    link: "https://www.potterybarn.com/",
  },
  {
    name: "Poppin",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/poppin.svg",
    hero_text:
      "Poppin specializes in office furniture and supplies designed to make workspaces vibrant and inspiring. Known for its colorful, modular office products, the company caters to the needs of modern workplaces by focusing on both functionality and style. Poppin's approach to office decor is innovative and user-friendly.",
    facts: [
      { key: "Founded", value: "2009" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale" },
      {
        key: "Features",
        value: "Colorful, modular, and customizable office solutions",
      },
      {
        key: "Iconic Products",
        value: "Series A Desk, Pitch Chair",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/poppin.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/poppin_hero.webp",
    sections: [
      {
        title: "Revolutionizing Modern Workspaces",
        text: "Poppin is at the forefront of transforming traditional work environments into dynamic, inspiring spaces. Founded in 2009, the brand has become synonymous with vibrant and functional office furniture that caters to the aesthetic and practical needs of modern workplaces. Their product range, featuring the Series A Desk and Pitch Chair, exemplifies modular design, allowing for flexible office layouts that can evolve with business needs.\n\nThis focus on modularity and customization helps Poppin stand out in the office furniture market, making workspaces not only more functional but also more enjoyable and adaptable to individual preferences.",
      },
      {
        title: "Commitment to Functionality and Style",
        text: "Poppin's dedication to merging style with functionality is evident in their colorful, user-friendly product designs. By using a vibrant palette and sleek, modern lines, Poppin products are designed to be as aesthetically pleasing as they are practical. The brand's approach extends beyond furniture to include a wide range of office supplies that enhance productivity and organization.\n\nMoreover, Poppin's commitment to providing customizable solutions ensures that each client can create a workspace that truly reflects their brand and culture, thereby enhancing employee satisfaction and productivity.",
      },
    ],
    link: "https://www.poppin.com/",
  },
  {
    name: "Pier 1 Imports",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/pier_1_imports.svg",
    hero_text:
      "Once a favorite destination for eclectic home furnishings, Pier 1 Imports offered a variety of items including furniture, textiles, and decor inspired by global trends. Known for its unique, colorful products, the company unfortunately closed its retail operations but continues to inspire through an online presence.",
    facts: [
      { key: "Founded", value: "1962" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value: "Eclectic and global inspiration, colorful and unique designs",
      },
      {
        key: "Iconic Products",
        value: "Papasan Chair, Mosaic Tables",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/pier_1_imports.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/pier_1.jpeg",

    sections: [
      {
        title: "Bringing Global Flair to Your Home",
        text: "Pier 1 Imports has been a favorite spot for finding unique and colorful home décor since it opened in 1962. They offered a wide range of furniture and decorations inspired by styles from around the world. Remember their comfy Papasan Chair or those eye-catching Mosaic Tables? They became classics!\n\nEven though their physical stores are no longer around, Pier 1 still inspires online. They've left a lasting mark on how we decorate our homes, with their love for vibrant colors and eclectic designs.",
      },
      {
        title: "Your Passport to Stylish Living",
        text: "At Pier 1 Imports, you could travel the world without leaving your home! Their products were like souvenirs from different cultures, bringing a touch of global flair to any space. From Moroccan rugs to Indian tapestries, each piece told a story.\n\nWhile you can't visit their stores anymore, their influence lives on in homes everywhere. Pier 1’s legacy of bringing color and uniqueness to our living spaces continues to inspire.",
      },
    ],
    link: "https://www.pier1.com/",
  },
  {
    name: "Oliver Space",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/oliver_space.svg",
    hero_text:
      "Oliver Space offers a unique approach to furnishing homes by blending the concept of furniture rental with the option to buy, making it ideal for transient lifestyles and modern living. Their products feature contemporary designs that cater to convenience and flexibility.",
    facts: [
      { key: "Founded", value: "2019" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Standard and rental models" },
      {
        key: "Features",
        value: "Flexible ownership options, contemporary design",
      },
      {
        key: "Iconic Products",
        value: "Hayes Sofa, Elmo Dining Table",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/oliver_space.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/oliver.webp",

    sections: [
      {
        title: "Furnishing Homes for Modern Living",
        text: "Imagine being able to furnish your home without the hassle of buying everything upfront. That's the idea behind Oliver Space. Since 2019, they've been offering a different way to make your space feel like home. They blend the convenience of furniture rental with the option to buy, perfect for those with busy lifestyles or who love a bit of flexibility.\n\nWith Oliver Space, you get contemporary designs that are both stylish and practical. Their Hayes Sofa and Elmo Dining Table are just a couple of examples of furniture that's perfect for modern living.",
      },
      {
        title: "Flexibility Meets Style",
        text: "Oliver Space understands that life is always changing, so why shouldn't your furniture adapt too? Their approach to furnishing gives you options. You can rent to try out different styles, or if you fall in love with something, you can buy it. It's all about making your home uniquely yours, without the commitment.\n\nWhether you're moving to a new city or just want to refresh your space, Oliver Space offers a hassle-free way to furnish your home with contemporary designs that fit your lifestyle.",
      },
    ],
    link: "https://www.oliver.space/",
  },
  // {
  //   name: "Maiden Home",
  //   logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/maiden_home.svg",
  //   hero_text:
  //     "Maiden Home is known for its artisanal approach to furniture, offering handcrafted pieces made in America. With a commitment to quality and detail, the brand provides customized furniture that combines modern aesthetic with traditional techniques.",
  //   facts: [
  //     { key: "Founded", value: "2015" },
  //     { key: "Country", value: "United States" },
  //     { key: "Production", value: "United States" },
  //     { key: "Manufacturing", value: "Handcrafted" },
  //     {
  //       key: "Features",
  //       value: "Customization, artisan quality, locally sourced materials",
  //     },
  //     {
  //       key: "Iconic Products",
  //       value: "Dune Sofa, Warren Sectional",
  //     },
  //   ],
  //   hero_img:
  //     "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/maiden_home.jpg",
  //   section_img:
  //     "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/maiden_home.jpg",

  //   sections: [
  //     {
  //       title: "Crafting Furniture with Care",
  //       text: "Maiden Home takes a hands-on approach to furniture making, emphasizing artisanal craftsmanship and attention to detail. Founded in 2015, the brand prides itself on creating quality pieces right here in the USA, using traditional techniques passed down through generations.\n\nEach furniture item, like the Dune Sofa and Warren Sectional, is meticulously crafted to ensure durability and timeless appeal. From the frame construction to the upholstery, Maiden Home’s commitment to quality shines through in every aspect of their production process.",
  //     },
  //     {
  //       title: "Personalized for Your Home",
  //       text: "At Maiden Home, customization is key. They understand that every home is unique, which is why they offer a range of customization options to suit your preferences. Whether it’s choosing the fabric, the finish, or the details, you have the freedom to create furniture that complements your space perfectly.\n\nIn addition to customization, Maiden Home prioritizes sustainability by sourcing materials locally. This not only ensures quality but also reduces environmental impact, making their furniture a thoughtful choice for conscientious consumers.",
  //     },
  //   ],
  //   link: "https://www.maidenhome.com/",
  // },
  {
    name: "Living Spaces",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/living_spaces.svg",
    hero_text:
      "Living Spaces provides a wide range of contemporary furniture and home accessories at competitive prices. Known for offering spacious showrooms and an extensive online catalog, Living Spaces appeals to a broad demographic with its versatile style and affordable pricing.",
    facts: [
      { key: "Founded", value: "2003" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value: "Affordable pricing, extensive variety, family-friendly designs",
      },
      {
        key: "Iconic Products",
        value: "Magnolia Home Collection, Revive Sleep Centers",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/ls.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/living_spaces.jpg",
    sections: [
      {
        title: "Your Destination for Contemporary Living",
        text: "Living Spaces has been a go-to destination for contemporary furniture and home accessories since 2003. With spacious showrooms and an extensive online catalog, they cater to a wide range of customers looking for versatile styles at competitive prices.\n\nKnown for their family-friendly designs and affordable pricing, Living Spaces offers something for everyone. Whether you're furnishing a new home or updating your current space, you'll find a diverse selection of furniture and accessories to suit your taste and budget.",
      },
      {
        title: "Quality and Variety, Without the High Price Tag",
        text: "At Living Spaces, affordability doesn't mean compromising on quality. Their mass-produced furniture combines affordability with style and durability, making it accessible to a broad demographic. The Magnolia Home Collection and Revive Sleep Centers are just two examples of their iconic offerings, known for their blend of comfort and style.\n\nWith Living Spaces, you can shop with confidence, knowing that you're getting quality furniture that fits your lifestyle without breaking the bank. Whether you prefer shopping in-store or online, Living Spaces makes it easy to create a home that reflects your unique style.",
      },
    ],
    link: "https://www.livingspaces.com/",
  },
  {
    name: "Ikea",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/ikea.svg",
    hero_text:
      "Ikea, the Swedish giant, is synonymous with modern furniture that’s affordable and easy to assemble. Known globally, Ikea revolutionized furniture selling with its flat-pack delivery system. Its products range from furniture to home accessories, catering to diverse tastes and needs.",
    facts: [
      { key: "Founded", value: "1943" },
      { key: "Country", value: "Sweden" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value: "Affordable, functional design, DIY assembly",
      },
      {
        key: "Iconic Products",
        value: "Billy Bookcase, Poäng Chair",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/ikea_section.jpeg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/ikea_hero.jpeg",
    sections: [
      {
        title: "Where Modern Furniture Meets Affordability",
        text: "IKEA, the Swedish giant founded in 1943, has become synonymous with modern furniture that's both affordable and stylish. With a global presence, IKEA revolutionized furniture selling with its flat-pack delivery system, making it easy for customers to transport and assemble their purchases.\n\nFrom furniture to home accessories, IKEA offers a wide range of products to cater to diverse tastes and needs. Whether you're furnishing a small apartment or a large home, IKEA's functional design and affordable prices make it a popular choice for millions of customers worldwide.",
      },
      {
        title: "Designed for Your Lifestyle",
        text: "At IKEA, affordability doesn't mean compromising on style or quality. Their mass-produced furniture features functional designs that are both practical and aesthetically pleasing. Iconic products like the Billy Bookcase and Poäng Chair are beloved for their simplicity, versatility, and durability.\n\nOne of IKEA's hallmarks is its DIY assembly approach, allowing customers to easily build their furniture at home. This not only saves on delivery costs but also empowers customers to personalize their living spaces according to their unique needs and preferences.",
      },
    ],
    link: "https://www.ikea.com/",
  },
  {
    name: "Ethan Allen",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/ethan_allen.svg",
    hero_text:
      "Ethan Allen is a traditional American furniture brand known for its quality craftsmanship and classic style. Founded in 1932, it offers a wide range of furniture and decorating items, providing personalized service through in-house design consultants.",
    facts: [
      { key: "Founded", value: "1932" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Traditional and modern methods" },
      {
        key: "Features",
        value: "Customizable options, classic designs",
      },
      {
        key: "Iconic Products",
        value: "Turner Swivel Chair, Bennett Sofa",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/ethan_allen.jpg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/ethan_allen_section.jpeg",

    sections: [
      {
        title: "Crafting Classic American Furniture Since 1932",
        text: "Ethan Allen has been a pillar of traditional American furniture since its founding in 1932. Renowned for its quality craftsmanship and classic style, the brand offers a wide range of furniture and decorating items that stand the test of time.\n\nWith production spanning the globe, Ethan Allen combines traditional and modern manufacturing methods to create furniture that reflects both heritage and innovation. From the Turner Swivel Chair to the Bennett Sofa, their iconic products showcase the timeless elegance and superior craftsmanship that define the Ethan Allen brand.",
      },
      {
        title: "Personalized Service, Endless Possibilities",
        text: "At Ethan Allen, the shopping experience goes beyond just browsing furniture. In-house design consultants provide personalized service to help customers bring their vision to life. With customizable options and classic designs, Ethan Allen offers endless possibilities for creating spaces that are as unique as the individuals who inhabit them.\n\nWhether you're furnishing a cozy home or a grand estate, Ethan Allen's commitment to quality and personalized service ensures that every piece of furniture reflects your style and enhances your living space.",
      },
    ],
    link: "https://www.ethanallen.com/",
  },
  {
    name: "Design Within Reach",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/design_within_reach.svg",
    hero_text:
      "Design Within Reach makes authentic modern furniture accessible to the public, offering iconic designs from famed designers. The brand is known for its high-quality reproductions of classic modernist furniture, ensuring customers receive pieces that are both beautiful and functional.",
    facts: [
      { key: "Founded", value: "1998" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      {
        key: "Manufacturing",
        value: "Licensed manufacturing of classic designs",
      },
      {
        key: "Features",
        value: "High-end modernist designs, accessibility of iconic pieces",
      },
      {
        key: "Iconic Products",
        value: "Eames Lounge Chair, Barcelona Chair",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/iconic_chair_designs_blog/tulip.jpeg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/iconic_chair_designs_blog/eames.jpeg",
    sections: [
      {
        title: "Explore Iconic Modern Furniture",
        text: "Design Within Reach is dedicated to bringing iconic modern furniture to homes everywhere. Since 1998, they've made authentic designs accessible to all. Their collection features renowned pieces from legendary designers, ensuring everyone can enjoy timeless elegance.\n\nFrom the inviting embrace of the Eames Lounge Chair to the sleek sophistication of the Barcelona Chair, each piece tells a story of innovation and craftsmanship, ready to transform any living space into a sanctuary of modern style.",
      },
      {
        title: "Experience Timeless Design",
        text: "Discover the allure of high-end modernist designs with Design Within Reach. Through licensed manufacturing of classic designs, they offer the opportunity to infuse any space with the essence of iconic pieces.\n\nWhether you're a design enthusiast or simply appreciate timeless beauty, their curated collection offers accessibility without compromising on quality or authenticity. Let Design Within Reach help unlock the beauty of modernist design and create a home that reflects your unique style.",
      },
    ],
    link: "https://www.dwr.com/",
  },
  {
    name: "Crate & Barrel",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/crate_and_barrel.svg",
    hero_text:
      "Crate & Barrel is a leader in the home furnishings industry, offering a wide variety of high-quality, stylish furniture, home accessories, and decor. Known for its clean, modern lines, the brand caters to upscale tastes with a mix of contemporary and classic styles.",
    facts: [
      { key: "Founded", value: "1962" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value: "Contemporary and classic styles, high-quality materials",
      },
      {
        key: "Iconic Products",
        value: "Lounge II Sofa, Basque Dining Table",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/crate_and_barrel_hero.jpg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/crate_and_barrel_section.jpeg",
    sections: [
      {
        title: "Commitment to Design Excellence",
        text: "Crate & Barrel stands out in the home furnishings market with its unwavering commitment to design excellence. From the sleek lines of the Lounge II Sofa to the rustic charm of the Basque Dining Table, the brand seamlessly blends contemporary and classic styles.\n\nBy collaborating with renowned designers and up-and-coming talents, Crate & Barrel ensures that each product not only meets high aesthetic standards but also remains functional and comfortable.\n\nThe focus on innovative design has helped the brand maintain its status as a trendsetter in the industry, continuously introducing fresh and inspiring collections that resonate with sophisticated consumers.",
      },
      {
        title: "Global Influence and Accessibility",
        text: "Since its founding in 1962, Crate & Barrel has expanded its influence globally, offering a wide range of stylish and high-quality furniture and home accessories.\n\nWith production facilities around the world, the brand leverages global resources to maintain quality and meet diverse consumer needs. This global approach enables Crate & Barrel to deliver its distinct aesthetic to international markets, making it accessible to a wide audience.\n\nThe brand's ability to combine global production with localized retail experiences in various countries ensures that customers everywhere can enjoy its unique blend of style and quality.",
      },
    ],
    link: "https://www.crateandbarrel.com/",
  },
  {
    name: "CB2",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/cb2.svg",
    hero_text:
      "CB2 is a modern furniture store targeting urban apartment dwellers and young professionals. A division of Crate & Barrel, CB2 offers edgier, sleeker furniture designs that are both functional and stylish, fitting well into smaller living spaces.",
    facts: [
      { key: "Founded", value: "2000" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value: "Modern, trendy designs, compact living solutions",
      },
      {
        key: "Iconic Products",
        value:
          'Stilt 42" High Dining Table, Movie Salt and Pepper Queen Sleeper Sofa',
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/cb2.jpg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/cb2_sect.jpeg",
    sections: [
      {
        title: "Innovative Designs for Urban Living",
        text: "CB2 distinguishes itself through innovative furniture designs tailored for the modern urban dweller. Recognizing the challenges of smaller living spaces, CB2 focuses on compact, multi-functional furniture that maximizes utility without sacrificing style.\n\nProducts like the 'Stilt 42\" High Dining Table' and the 'Movie Salt and Pepper Queen Sleeper Sofa' exemplify this philosophy, offering versatile solutions that are both practical and fashionable. This commitment to design-led, space-saving furniture has positioned CB2 as a favorite among young professionals and trend-conscious consumers.",
      },
      {
        title: "Sustainability and Global Reach",
        text: "CB2 is committed to sustainability while maintaining a broad global reach in its operations. With production and manufacturing facilities spread worldwide, the brand ensures that its modern, trendy furniture is accessible to a global audience.\n\nThis international approach is complemented by an emphasis on environmentally responsible practices, striving to reduce the ecological footprint of their production processes.\n\nThrough innovative design and sustainable practices, CB2 not only meets the current trends but also leads the way in responsible furniture manufacturing.",
      },
    ],
    link: "https://www.cb2.com/",
  },
  {
    name: "Blu Dot",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/blu_dot.svg",
    hero_text:
      "Blu Dot is a Minneapolis-based designer and maker of modern furnishings, spanning sofas, tables, and accessories. Known for its creative, affordable, and inventive furniture, Blu Dot aims to bring good design to as many people as possible.",
    facts: [
      { key: "Founded", value: "1997" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Modern manufacturing techniques" },
      {
        key: "Features",
        value: "Innovative designs, affordable modernism",
      },
      {
        key: "Iconic Products",
        value: "Nomad Sofa, Real Good Chair",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/blu_dot_hero.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/blu_dot.jpeg",
    sections: [
      {
        title: "Affordable Modernism for Everyone",
        text: "Blu Dot has made a name for itself by offering creatively designed modern furniture at affordable prices. With a mission to democratize good design, Blu Dot crafts pieces that are both aesthetically pleasing and accessible to a wide audience. The 'Nomad Sofa' and the 'Real Good Chair' are perfect examples of the brand's philosophy, combining innovative design with practicality.\n\nThis approach not only caters to design enthusiasts looking for budget-friendly options but also ensures that more people can enjoy the beauty and functionality of modern furnishings.",
      },
      {
        title: "Creative and Sustainable Manufacturing",
        text: "Using modern manufacturing techniques, Blu Dot maintains a balance between creativity and sustainability. The company's global production facilities are geared towards minimizing waste and enhancing efficiency, reflecting a commitment to environmental responsibility.\n\nThis focus on sustainable practices extends through every aspect of Blu Dot’s operations, from the design process to the final product. By continuously exploring new materials and technologies, Blu Dot not only pushes the boundaries of design but also promotes a more sustainable approach to furniture manufacturing.",
      },
    ],
    link: "https://www.bludot.com/",
  },
  {
    name: "Autonomous",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/autonomous.svg",
    hero_text:
      "Autonomous is known for its smart office furniture designed to boost productivity. Specializing in ergonomic chairs and adjustable desks, Autonomous combines technology and comfort to enhance office environments.",
    facts: [
      { key: "Founded", value: "2015" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale" },
      {
        key: "Features",
        value:
          "Ergonomic design, smart office solutions, technological integration",
      },
      {
        key: "Iconic Products",
        value: "SmartDesk 2, ErgoChair 2",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/autonomous_hero.jpeg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/autonomous_section.jpeg",
    sections: [
      {
        title: "Ergonomics Meets Innovation",
        text: "Autonomous revolutionizes the way we work through its line of smart office furniture, combining ergonomic designs with advanced technology to enhance workplace productivity.\n\nThe brand's standout products, the SmartDesk 2 and ErgoChair 2, are engineered to promote comfort and health in office settings. These products adjust to individual needs, encouraging better posture and more dynamic work environments.\n\nThis blend of ergonomic solutions and user-friendly technology exemplifies Autonomous's commitment to improving the way people work every day.",
      },
      {
        title: "Global Manufacturing with a Technological Edge",
        text: "With a global manufacturing footprint, Autonomous leverages Large scale techniques enhanced by the latest in industrial technology to deliver consistently high-quality products.\n\nThe use of precision automation ensures each piece of furniture meets rigorous standards of comfort and durability.\n\nThis approach not only streamlines production but also allows for the integration of smart features in office furniture, making Autonomous a leader in the smart office space.",
      },
    ],
    link: "https://www.autonomous.ai/",
  },
  {
    name: "Ashley Furniture",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/ashley_furniture.svg",
    hero_text:
      "As the largest furniture manufacturer in the world, Ashley Furniture is known for its wide array of home furnishings at accessible price points. The brand offers diverse styles and products, catering to a broad customer base with its extensive retail network.",
    facts: [
      { key: "Founded", value: "1945" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale production" },
      {
        key: "Features",
        value:
          "Broad range of products, affordability, extensive distribution network",
      },
      {
        key: "Iconic Products",
        value: "Porter Bedroom Collection, Darcy Sofa",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/ashley_furniture.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/ashley_furniture_section.jpeg",
    sections: [
      {
        title: "A Legacy of Quality and Affordability",
        text: "Ashley Furniture has established itself as a powerhouse in the furniture industry since its founding in 1945. Known for producing a broad range of home furnishings that combine quality with affordability, Ashley Furniture caters to varying tastes and budgets.\n\nTheir extensive product lines, including the popular Porter Bedroom Collection and the Darcy Sofa, showcase the brand's commitment to offering stylish, high-quality furniture that enhances any home. This commitment to quality at affordable price points makes Ashley a preferred choice for families around the world.",
      },
      {
        title: "Extensive Retail and Distribution Network",
        text: "With one of the most extensive retail networks in the furniture industry, Ashley Furniture ensures that its products are easily accessible to a vast audience globally. This widespread distribution network is supported by advanced manufacturing facilities that enable Large scale production on a global scale.\n\nThe brand’s ability to distribute a diverse range of furniture styles quickly and efficiently is a cornerstone of its business model, ensuring that customers can enjoy the latest in home furnishings with convenience and ease.",
      },
    ],
    link: "https://www.ashleyfurniture.com/",
  },
  {
    name: "Article",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/article.svg",
    hero_text:
      "Article simplifies the furniture buying process by selling directly to consumers online, eliminating the middleman. The brand offers modern, sleek furniture designs at reasonable prices, favored for their aesthetics and quality.",
    facts: [
      { key: "Founded", value: "2013" },
      { key: "Country", value: "Canada" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Online-only, streamlined production" },
      {
        key: "Features",
        value: "Direct-to-consumer, modern design, competitive pricing",
      },
      {
        key: "Iconic Products",
        value: "Sven Sofa, Seno Dining Table",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/article_hero.jpg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/article.webp",
    sections: [
      {
        title: "Revolutionizing Furniture Shopping",
        text: "Article has transformed the furniture retail sector by adopting a direct-to-consumer model that cuts out the middleman, allowing the company to offer high-quality, modern furniture at competitive prices. Founded in 2013, the company has quickly become popular among consumers looking for sleek, contemporary designs without the traditional retail markup.\n\nProducts like the Sven Sofa and the Seno Dining Table reflect Article's commitment to blending style with functionality, catering to the aesthetic needs of modern homes while ensuring customer satisfaction through affordable pricing.",
      },
      {
        title: "Efficiency in Design and Distribution",
        text: "By operating exclusively online and streamlining production, Article can respond rapidly to market trends and consumer demands. This efficiency extends to their manufacturing process, which is designed to minimize waste and maximize productivity.\n\nThe global production network supports quick delivery times and maintains quality control, ensuring that each piece meets Article’s high standards. The streamlined approach not only enhances customer experience by reducing wait times but also supports sustainability in the furniture industry.",
      },
    ],
    link: "https://www.article.com/",
  },
  {
    name: "Herman Miller",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/hermann_miller.svg",
    hero_text:
      "Herman Miller is a pioneer in the design of office furniture and ergonomic chairs, famed for the iconic Aeron chair. The company’s focus on innovative, high-quality design extends to home furnishings, emphasizing durability and comfort.",
    facts: [
      { key: "Founded", value: "1905" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Innovative and sustainable design" },
      {
        key: "Features",
        value: "Ergonomic office products, iconic modern furniture",
      },
      {
        key: "Iconic Products",
        value: "Aeron Chair, Eames Lounge Chair",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/iconic_chair_designs_blog/eames.jpeg?tr=w-1000",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/hermann_miller.webp",
    sections: [
      {
        title: "Iconic Designs and Ergonomic Excellence",
        text: "Herman Miller has earned a reputation for exceptional design, particularly through its ergonomic office furniture like the Aeron Chair and the iconic Eames Lounge Chair. These products not only demonstrate a mastery of form and function but also have become benchmarks in the design of modern furniture.\n\nThe Eames Lounge Chair, in particular, represents a perfect blend of luxury and comfort, making it a staple in both offices and homes. Herman Miller's commitment to innovative, thoughtful design continues to influence the industry and captivate consumers globally.",
      },
      {
        title: "A Legacy of Design Innovation",
        text: "Since its founding in 1905, Herman Miller has been at the forefront of design innovation. The company has collaborated with numerous legendary designers, leading to groundbreaking products that combine aesthetic elegance with practical functionality.\n\nThese collaborations have produced not just furniture but pieces of cultural significance that have defined consumer expectations and set design trends in the furniture industry. Herman Miller's dedication to high-quality, innovative design ensures its place as a leader in the global furniture market.",
      },
    ],
    link: "https://www.hermanmiller.com/",
  },
  {
    name: "Knoll",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/knoll.svg",
    hero_text:
      "Knoll is a renowned designer of office and home furnishings, known for its classic American architectural and furniture designs. Knoll produces timeless pieces that represent significant artistic movements and collaborations with famous architects.",
    facts: [
      { key: "Founded", value: "1938" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "High-quality production" },
      {
        key: "Features",
        value: "Modernist design, collaborations with famous architects",
      },
      {
        key: "Iconic Products",
        value: "Barcelona Chair, Tulip Table",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/iconic_chair_designs_blog/wassily.jpeg?tr=w-1000",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/iconic_chair_designs_blog/barcelona.jpeg?tr=w-1000",

    sections: [
      {
        title: "Timeless Designs and Architectural Influence",
        text: "Knoll is celebrated for its significant contributions to modernist furniture design, producing pieces that are both architecturally inspired and timeless. The brand's collaboration with renowned figures like Mies van der Rohe and Eero Saarinen has led to the creation of iconic furniture such as the Barcelona Chair and the Tulip Table.\n\nThese designs not only reflect Knoll's commitment to aesthetic and functional excellence but also their role in shaping the modern American furniture landscape. Each piece represents a melding of artistic movements and practical design, ensuring their place in both homes and offices as enduring symbols of style.",
      },
      {
        title: "A Legacy of Collaboration and Quality",
        text: "Since its inception in 1938, Knoll has set a standard for high-quality production and innovative design, working alongside famous architects to produce distinctive, durable furnishings. This tradition of collaboration ensures that Knoll’s offerings are at the forefront of design innovation, blending artistic expression with practical functionality.\n\nThe company's adherence to these principles has not only secured a loyal following but has also cemented its status as a leader in the design and production of office and home furnishings.",
      },
    ],
    link: "https://www.knoll.com/",
  },
  {
    name: "Roche Bobois",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/roche_bobois.svg",
    hero_text:
      "Roche Bobois is a luxury French furniture brand celebrated for its chic, avant-garde designs. Known for its artistic collaborations, the brand offers exclusive made-to-order collections that blend modern aesthetics with traditional craftsmanship.",
    facts: [
      { key: "Founded", value: "1960" },
      { key: "Country", value: "France" },
      { key: "Production", value: "Europe" },
      { key: "Manufacturing", value: "Custom made-to-order" },
      {
        key: "Features",
        value:
          "Luxury furnishings, avant-garde designs, artistic collaborations",
      },
      {
        key: "Iconic Products",
        value: "Mah Jong Sofa, Bubble Sofa",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/roche_bobois_hero.jpeg",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/roche_bobois.webp",
    sections: [
      {
        title: "Artistic Collaborations and Avant-Garde Design",
        text: "Roche Bobois is renowned for its luxury furniture that embodies chic, avant-garde design. Since its founding in 1960, the brand has been at the forefront of introducing innovative aesthetics into the home, working closely with designers and artists to create unique, artistic pieces.\n\nThe Mah Jong Sofa and the Bubble Sofa exemplify Roche Bobois’s approach, blending modern style with versatile functionality. These collections not only reflect contemporary art influences but also capture the essence of personalized luxury, making each piece a statement in any setting.",
      },
      {
        title: "Craftsmanship and Customization",
        text: "Emphasizing traditional craftsmanship, Roche Bobois offers exclusive, made-to-order furniture that caters to individual tastes and needs. This commitment to customization allows clients to be a part of the design process, selecting materials and finishes that reflect their personal style.\n\nManufactured primarily in Europe, each piece by Roche Bobois is a testament to the skill of its artisans, ensuring longevity and exclusivity. This dedication to craftsmanship and customer involvement ensures that each furniture item is not only a functional product but a piece of art.",
      },
    ],
    link: "https://www.roche-bobois.com/en-US",
  },
  {
    name: "Zuo Modern",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/zuo_modern.svg",
    hero_text:
      "Zuo Modern provides trendy and affordable furniture targeted at a younger demographic. The brand is known for its innovative designs that incorporate vibrant colors and contemporary styling, ideal for urban living.",
    facts: [
      { key: "Founded", value: "2005" },
      { key: "Country", value: "United States" },
      { key: "Production", value: "Global" },
      { key: "Manufacturing", value: "Large scale" },
      {
        key: "Features",
        value: "Contemporary designs, affordability, vibrant colors",
      },
      {
        key: "Iconic Products",
        value: "Lider Comfort Office Chair, Providence Sofa",
      },
    ],
    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/zuo_modern_hero.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/zuo_modern.webp",
    sections: [
      {
        title: "Contemporary Style for Modern Living",
        text: "Zuo Modern is distinguished by its innovative and affordable furniture, characterized by vibrant colors and contemporary styling. The brand's products, such as the Lider Comfort Office Chair and the Providence Sofa, are designed to add flair and functionality to any living space.\n\nThese pieces embody Zuo Modern’s commitment to blending style with comfort, offering unique designs that cater to anyone looking to enhance their home or office environment with modern aesthetics.",
      },
      {
        title: "Global Production and Innovative Design",
        text: "Founded in 2005, Zuo Modern has rapidly grown into a key player in the furniture industry with its global production capabilities and focus on contemporary design. The use of Large scale techniques allows the brand to maintain affordability while ensuring that its furniture meets a high standard of quality and style.\n\nThis strategy supports Zuo Modern’s ability to continuously update its product offerings with fresh, innovative designs that appeal to consumers seeking modern and stylish furniture solutions.",
      },
    ],
    link: "https://www.zuomod.com/",
  },
  {
    name: "de Sede",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/de_sede.svg",
    hero_text:
      "De Sede of Switzerland is revered for its luxurious leather furniture, crafted with impeccable quality and innovative designs. The brand started as a small saddler's workshop and has grown into a premier manufacturer, renowned for its dedication to craftsmanship and detail.",
    facts: [
      { key: "Founded", value: "1962" },
      { key: "Country", value: "Switzerland" },
      { key: "Production", value: "Switzerland" },
      { key: "Manufacturing", value: "Handmade, unique status" },
      {
        key: "Features",
        value: "Leather expertise, highest quality, luxurious designs",
      },
      {
        key: "Iconic Products",
        value: "DS-600, DS-47, DS-140",
      },
    ],

    hero_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/hero_images/de_sede.webp",
    section_img:
      "https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/section_images/de_sede_section.webp",
    sections: [
      {
        title: "Eco-Conscious Manufacturing",
        text: "de Sede has long been distinguished by a manufacturing process focused meticulously on quality.\n\nA special resource-saving process in leather cutting, strict quality standards, and partially hand-sewn seams ensure each piece is uniquely luxurious. At the same time, the production is energy-efficient and conserves energy, a commitment that de Sede proudly acknowledges.\n\nEnvironmental responsibility and sustainability are consciously addressed, reflected also in the management certifications (1996, 2007, and 2010) for environmentally friendly production according to ISO 14001 standards. Whether it's classics from the early days or modern recliners, the manufacturer today offers models for every taste, continuously evolving without losing the distinctive de Sede touch.",
      },
      {
        title: "A Story of Success",
        text: "Founded in 1962 by master saddler Ernst Lüthy in the small town of Klingnau, Switzerland, what began as a family business specialized in custom-made leather furniture soon transformed into de Sede AG in 1965.\n\nCollaborating with top international designers led to unique design creations that became cult models. Today, de Sede stands as one of the leading brand manufacturers of exclusive designer leather furniture, with 110 employees producing over 11,500 pieces of furniture annually for distribution in more than 69 countries.\n\nThe acquisition of FSM Franz Sitzmöbel GmbH in 2000 meant that FSM furniture would also be produced in de Sede's facilities, expanding their range and influence in the market.",
      },
    ],
    link: "https://www.desede.ch/en/",
  },
];
