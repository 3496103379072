import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inistialState = {
	loading: false,
	notifications: [],
	count: 0,
	notPreviewed: 0,
	maxed: false,
};

const notifications = createSlice({
	name: 'notifications',
	initialState: inistialState,
	reducers: {
		NOTIFICATIONS_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},

		NOTIFICATIONS_SUCCESS(state, action) {
			const { notifications, notPreviewed, maxed } = action.payload;

			return (state = {
				notifications,
				count: notifications.length,
				maxed,
				notPreviewed,
				loading: false,
			});
		},

		NOTIFICATIONS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},

		NOTIFICATIONS_PREVIEWED(state, action) {
			return (state = { ...state, notPreviewed: 0 });
		},
	},
});

export const NOTIFICATIONS_LOADING =
	notifications.actions.NOTIFICATIONS_LOADING;

export const NOTIFICATIONS_SUCCESS =
	notifications.actions.NOTIFICATIONS_SUCCESS;
export const NOTIFICATIONS_PREVIEWED =
	notifications.actions.NOTIFICATIONS_PREVIEWED;

export const NOTIFICATIONS_ERROR = notifications.actions.NOTIFICATIONS_ERROR;

export const LOAD_NOTIFICATIONS = (loadMore) => async (dispatch, getState) => {
	const { notifications } = await getState();

	if (notifications.loading) {
		return;
	}

	dispatch(NOTIFICATIONS_LOADING());

	// return;
	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/auth/get-nots`, {
			skip: loadMore ? notifications.count : 0,
		})
		.then(async (res) => {
			console.log(res.data);
			dispatch(NOTIFICATIONS_SUCCESS(res.data));
		})
		.catch(async (error) => {
			dispatch(NOTIFICATIONS_ERROR());
		});
};

export const PREVIEW_NOTIFICATIONS =
	(loadMore) => async (dispatch, getState) => {
		dispatch(NOTIFICATIONS_PREVIEWED());

		// return;
		await axios
			.get(`${process.env.REACT_APP_SERVER_API}/api/auth/preview-nots`)
			.then(async (res) => {
				console.log(res.data);
			})
			.catch(async (error) => {});
	};

export const REMOVE_NOTIFICATION = (notId) => async (dispatch, getState) => {
	const { notifications } = getState('notifications');

	dispatch(NOTIFICATIONS_LOADING());
	try {
		let newNotifications = JSON.parse(
			JSON.stringify([...notifications.notifications])
		);

		const notIndex = newNotifications.findIndex(
			(i) => i._id.toString() === notId.toString()
		);

		newNotifications[notIndex].seen = true;

		dispatch(
			NOTIFICATIONS_SUCCESS({
				notifications: newNotifications.sort((a, b) =>
					a.date < b.date ? 1 : -1
				),
				maxed: notifications.maxed,
				notPreviewed: notifications.notPreviewed,
			})
		);

		await axios
			.get(
				`${process.env.REACT_APP_SERVER_API}/api/auth/remove-notification/${notId}`
			)
			.then((res) => {
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	} catch (err) {
		console.log(err);
		dispatch(NOTIFICATIONS_ERROR());
	}
};

export default notifications;
