import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

const BlogCollectionsHelmet = () => {
  const { id } = useParams();

  const collections = [
    {
      id: 1,
      name: "Sustainability",
      url_id: "sustainability",
      meta_description:
        "Explore sustainable interior design with used furniture. Discover upcycling, ethical thrifting, and tips for stylish, eco-friendly living spaces.",
      meta_title: "Sustainability in Interior Design | Kashew",
    },
    {
      id: 2,
      name: "Interior Design",
      url_id: "interior-design",
      meta_description:
        "Unlock the potential of used furniture for interior design. Get inspired by our tips for creating personalized, budget-friendly living spaces.",
      meta_title: "Unique Interior Design Ideas | Kashew",
    },
    {
      id: 3,
      name: "Seller Spotlight",
      url_id: "seller-spotlight",
      meta_description:
        "Dive into the stories of our professional resellers and their unique furniture pieces that add multiple lives to every item.",
      meta_title: "Meet Our Sellers | Kashew",
    },
    {
      id: 4,
      name: "Press Release",
      url_id: "press-releases",
      meta_description:
        "Stay updated with Kashew's press releases. Learn about our new initiatives, collaborations, and impacts on the used furniture industry.",
      meta_title: "Latest News & Updates | Kashew",
    },
  ];

  const displayInfo = collections.find(
    (col) => col.url_id === id.toLowerCase()
  );

  return (
    <Helmet>
      {id && displayInfo && (
        <>
          <title data-rh="true">{displayInfo?.meta_title}</title>
          <meta
            name="description"
            content={displayInfo?.meta_description}
            data-rh="true"
          />
          <meta
            property="og:title"
            content={displayInfo?.meta_title}
            data-rh="true"
          />
          <meta
            property="og:description"
            content={displayInfo?.meta_description}
            data-rh="true"
          />
          <meta
            property="og:image"
            content="https://ik.imagekit.io/kuj9zqlrx0or/Static/5_designer_cali/hero.jpg?tr=w-2000"
            data-rh="true"
          />

          <meta
            property="og:image:alt"
            content={`${displayInfo?.name} Furniture Blog | Kashew | Used Designer Furniture | Sofas | Chairs | Tables | Beds | Lighting | Decor`}
            data-rh="true"
          />

          <meta
            property="og:url"
            content={`https://kashew.com/blog/collections/${displayInfo?.url_id}`}
            data-rh="true"
          />

          <meta
            property="canonical"
            content={`https://kashew.com/blog/collections/${displayInfo?.url_id}`}
            data-rh="true"
          />
        </>
      )}
    </Helmet>
  );
};

export default BlogCollectionsHelmet;
