import React from 'react';
import classes from './Loader.module.css';
import InfiniteLoadGif from '../../assets/infinite2.gif';

const Loader = () => {
	return (
		<div className={classes.loaderBox}>
			<img
				src={InfiniteLoadGif}
				alt="Loading..."
				style={{ width: '20%', margin: 'auto' }}
			/>
		</div>
	);
};

export default Loader;
