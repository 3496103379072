import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	addingProgress: { message: '', loading: '' },
	updatingProgress: { message: '', loading: '' },
	soldProgress: { message: '', loading: '' },
	progressMsg: { message: '', loading: '' },
};
const csvUpload = createSlice({
	name: 'csvUpload',
	initialState: initialState,
	reducers: {
		SET_ADD_PROGRESS(state, action) {
			state.addingProgress = action.payload;
		},
		SET_UPDATE_PROGRESS(state, action) {
			state.updatingProgress = action.payload;
		},
		SET_SOLD_PROGRESS(state, action) {
			state.soldProgress = action.payload;
		},
		SET_PROGRESS_MSG(state, action) {
			state.progressMsg = action.payload;
		},
	},
});

export const SET_ADD_PROGRESS = csvUpload.actions.SET_ADD_PROGRESS;
export const SET_UPDATE_PROGRESS = csvUpload.actions.SET_UPDATE_PROGRESS;
export const SET_SOLD_PROGRESS = csvUpload.actions.SET_SOLD_PROGRESS;
export const SET_PROGRESS_MSG = csvUpload.actions.SET_PROGRESS_MSG;

export default csvUpload;
