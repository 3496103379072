import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const ProfileHelmet = () => {
	const { id } = useParams();
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getUser = async () => {
			setLoading(true);
			await axios
				.get(`${process.env.REACT_APP_SERVER_API}/api/users/helmet/${id}`)
				.then((res) => {
					setUser(res.data);
				});
			setLoading(false);
		};
		getUser();
	}, [id]);

	return (
		<>
			{user && !loading && (
				<Helmet>
					<title>{`${user.firstName}'s Collection - For Sale`}</title>
					<meta
						name="description"
						content={`${user.firstName} Furniture On Kashew - Explore their collection of second hand furniture for sale`}
					/>
					<meta name="keywords" content={`${user.firstName}`} />
					<link
						rel="canonical"
						href={`https://www.kashew.com/profile/${user._id}`}
					/>
					<meta property="og:type" content="website" />
					<meta
						property="og:title"
						content={`${user.firstName}'s Collection - For Sale`}
					/>
					<meta
						property="og:description"
						content={`${user.firstName} Furniture On Kashew - Explore their collection of second hand furniture for sale`}
					/>
					<meta
						property="og:image"
						content={`${user.picture ? user.picture : ''}?tr=w-300,h=158`}
					/>
					<meta property="og:image:width" content="300" />
					<meta property="og:image:height" content="158" />
					<meta
						property="og:image:alt"
						content={`${user.firstName} Furniture Collection - For Sale`}
					/>
					<meta
						property="og:url"
						content={`https://www.kashew.com/profile/${user._id}`}
					/>
				</Helmet>
			)}
		</>
	);
};

export default ProfileHelmet;
