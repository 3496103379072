import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const allChats = createSlice({
	name: 'allChats',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		chats: [],
		maxed: false,
		notifications: 0,
	},
	reducers: {
		ALL_CHATS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		ALL_CHATS_SUCCESS(state, action) {
			const { chats, notifications, maxed, count, loaded } = action.payload;

			console.log(chats.length);
			return (state = {
				loading: false,
				chats,
				notifications,
				maxed,
				count,
				loaded: true,
			});
		},
		ALL_CHATS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: true });
		},
	},
});

export const ALL_CHATS_LOADING = allChats.actions.ALL_CHATS_LOADING;
export const ALL_CHATS_SUCCESS = allChats.actions.ALL_CHATS_SUCCESS;
export const ALL_CHATS_ERROR = allChats.actions.ALL_CHATS_ERROR;

export const GET_ALL_CHATS = (props) => async (dispatch, getState) => {
	const { auth } = await getState();

	dispatch(ALL_CHATS_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/chat/all-chats`)
		.then(async (res) => {
			let chats = [...res.data].sort((a, b) =>
				moment(
					a.messages.length > 0 &&
						a.messages[a.messages.length - 1] &&
						a.messages[a.messages.length - 1].date
				).format('YYYY-MM-DD kk:mm:ss') <
				moment(
					b.messages.length > 0 &&
						b.messages[b.messages.length - 1] &&
						b.messages[b.messages.length - 1].date
				).format('YYYY-MM-DD kk:mm:ss')
					? 1
					: -1
			);
			let notifications = 0;
			let maxed = true;
			let count = chats.length;

			for (let i of chats) {
				if (i.status && i.notification.users.includes(auth.user._id)) {
					notifications = notifications + 1;
				}
			}

			dispatch(ALL_CHATS_SUCCESS({ chats, notifications, maxed, count }));
		})
		.catch((err) => {
			console.log(err);
			dispatch(ALL_CHATS_ERROR());
		});
};

export const REMOVE_CHAT_NOT = (props) => async (dispatch, getState) => {
	const { allChats } = await getState();

	const { userId, chatId } = props;

	let newChats = [...allChats.chats];
	//ALL
	let found1 = newChats.find((i) => i._id === chatId);
	if (found1) {
		let found2 = JSON.parse(JSON.stringify(found1));
		found2.notification.users = found2.notification.users.filter(
			(i) => i.toString() !== userId
		);
		newChats = newChats.filter((i) => i._id !== found2._id);
		newChats.push(found2);
	}

	newChats.sort((a, b) =>
		moment(
			a.messages.length > 0 &&
				a.messages[a.messages.length - 1] &&
				a.messages[a.messages.length - 1].date
		).format('YYYY-MM-DD kk:mm:ss') <
		moment(
			b.messages.length > 0 &&
				b.messages[b.messages.length - 1] &&
				b.messages[b.messages.length - 1].date
		).format('YYYY-MM-DD kk:mm:ss')
			? 1
			: -1
	);

	let maxed = true;
	let notifications = 0;

	for (let i of newChats) {
		if (i.notification.users.includes(userId)) {
			notifications = notifications + 1;
		}
	}

	dispatch(
		ALL_CHATS_SUCCESS({
			chats: [...newChats],
			notifications,
			maxed: allChats.maxed,
			count: allChats.count,
		})
	);
};

export default { allChats };
