import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inistialState = {
	loading: false,
	listings: [],
	moveouts: [],
};
const userListings = createSlice({
	name: 'userListings',
	initialState: inistialState,
	reducers: {
		USER_LISTINGS_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},

		USER_LISTINGS_SUCCESS(state, action) {
			return (state = {
				listings: action.payload.listings,
				moveouts: action.payload.moveouts,
				loading: false,
			});
		},

		USER_LISTINGS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
		USER_LISTINGS_LOADED(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const USER_LISTINGS_LOADING = userListings.actions.USER_LISTINGS_LOADING;
export const USER_LISTINGS_SUCCESS = userListings.actions.USER_LISTINGS_SUCCESS;
export const USER_LISTINGS_ERROR = userListings.actions.USER_LISTINGS_ERROR;
export const USER_LISTINGS_LOADED = userListings.actions.USER_LISTINGS_LOADED;

export const LOAD_USER_LISTINGS = () => async (dispatch) => {
	dispatch(USER_LISTINGS_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/listings/author`)
		.then(async (res) => {
			dispatch(USER_LISTINGS_SUCCESS(res.data));
		})
		.catch(async (error) => {
			console.log(error);
			dispatch(USER_LISTINGS_ERROR());
		});
};

export default userListings;
