//Redux store
import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import alert from './reducers/alert';
import articles from './reducers/articles';
import auth from './reducers/auth';
import chats from './reducers/chats';
import consignments from './reducers/consignments';
import csvUpload from './reducers/csvUpload';
import dashboardProducts from './reducers/dashboardProducts';
import favorites from './reducers/favorites';
import listings from './reducers/listings';
import notifications from './reducers/notifications';
import orders from './reducers/orders';
import requests from './reducers/requests';
import soldListings from './reducers/soldListings';
import userListings from './reducers/userListings';
import users from './reducers/users';
// console.log(activeChats);
const store = configureStore(
	{
		reducer: {
			alert: alert.reducer,
			auth: auth.reducer,
			users: users.reducer,
			listings: listings.reducer,
			articles: articles.reducer,
			favorites: favorites.reducer,
			allChats: chats.allChats.reducer,
			purchases: orders.purchases.reducer,
			sales: orders.sales.reducer,
			userListings: userListings.reducer,
			requests: requests.reducer,
			notifications: notifications.reducer,
			soldListings: soldListings.reducer,
			csvUpload: csvUpload.reducer,
			consignments: consignments.reducer,
			dashboardProducts: dashboardProducts.reducer,
		},
		devTools: process.env.NODE_ENV !== 'production',
	},

	applyMiddleware(thunk)
);

export default store;
