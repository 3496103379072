import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

//Redux
import { Provider } from 'react-redux';
import store from './redux/index';

ReactDOM.render(
	<BrowserRouter basename="/">
		<Provider store={store}>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);
