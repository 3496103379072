import { createSlice } from "@reduxjs/toolkit";
import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const inistialState = {
  loading: false,
  listings: [],
  paginationData: {},
  uniqueBrands: [],
  uniqueRegions: [],
  uniqueMaterials: {},
  uniqueStyles: {},
  uniqueRooms: {},
  uniqueCategories: {},
  uniqueSubCategories: {},
  uniqueTypes: {},
  all: {
    loading: false,
    uniqueBrands: [],
    uniqueRegions: [],
    uniqueMaterials: {},
    uniqueStyles: {},
    uniqueRooms: {},
    uniqueCategories: {},
    uniqueSubCategories: {},
    uniqueTypes: {},
  },
};
const listings = createSlice({
  name: "listings",
  initialState: inistialState,
  reducers: {
    LISTINGS_LOADING(state, action) {
      return (state = { ...state, loading: true });
    },
    NUMBERS_LOADING(state, action) {
      return (state = { ...state, all: { ...state.all, loading: true } });
    },
    EARLY_LISTINGS_LOADING(state, action) {
      return (state = { ...state, loading: true });
    },
    LISTINGS_SUCCESS(state, action) {
      return (state = {
        ...state,
        listings: action.payload.listings,
        loading: false,
        paginationData: action.payload.paginationData,
        uniqueBrands: action.payload.uniqueBrands,
        uniqueRegions: action.payload.uniqueRegions,
        uniqueMaterials: action.payload.uniqueMaterials,
        uniqueStyles: action.payload.uniqueStyles,
        uniqueRooms: action.payload.uniqueRooms,
        uniqueCategories: action.payload.uniqueCategories,
        uniqueSubCategories: action.payload.uniqueSubCategories,
        uniqueTypes: action.payload.uniqueTypes,
      });
    },
    LOAD_NUMBERS_SUCCESS(state, action) {
      return (state = {
        ...state,
        all: {
          loading: false,
          paginationData: action.payload.paginationData,
          uniqueBrands: action.payload.uniqueBrands,
          uniqueRegions: action.payload.uniqueRegions,
          uniqueMaterials: action.payload.uniqueMaterials,
          uniqueStyles: action.payload.uniqueStyles,
          uniqueRooms: action.payload.uniqueRooms,
          uniqueCategories: action.payload.uniqueCategories,
          uniqueSubCategories: action.payload.uniqueSubCategories,
          uniqueTypes: action.payload.uniqueTypes,
        },
      });
    },
    EARLY_LISTINGS_SUCCESS(state, action) {
      return (state = {
        ...state,
        listings: action.payload.listings,
        moveouts: action.payload.moveouts,
        loading: true,
      });
    },
    LISTINGS_ERROR(state, action) {
      return (state = { ...state, loading: false });
    },
    NUMBERS_ERROR(state, action) {
      return (state = { ...state, all: { ...state.all, loading: false } });
    },
    LISTINGS_LOADED(state, action) {
      return (state = { ...state, loading: false });
    },
  },
});

export const LISTINGS_LOADING = listings.actions.LISTINGS_LOADING;
export const EARLY_LISTINGS_LOADING = listings.actions.EARLY_LISTINGS_LOADING;
export const LISTINGS_SUCCESS = listings.actions.LISTINGS_SUCCESS;
export const EARLY_LISTINGS_SUCCESS = listings.actions.EARLY_LISTINGS_SUCCESS;
export const LISTINGS_ERROR = listings.actions.LISTINGS_ERROR;
export const LISTINGS_LOADED = listings.actions.LISTINGS_LOADED;
export const NUMBERS_LOADING = listings.actions.NUMBERS_LOADING;
export const LOAD_NUMBERS_SUCCESS = listings.actions.LOAD_NUMBERS_SUCCESS;
export const NUMBERS_ERROR = listings.actions.NUMBERS_ERROR;

export const LOAD_LISTINGS =
  ({ page = 1, locationSearch = "", userToken }) =>
  async (dispatch, getState) => {
    dispatch(LISTINGS_LOADING());
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.set("page", page);
    // Requires faceting
    let brand = searchParams.get("brand");
    let location = searchParams.get("location");
    let style = searchParams.get("style");
    let material = searchParams.get("material");

    let category = searchParams.get("category");
    let subCategory = searchParams.get("option");
    let type = searchParams.get("subType");
    let color = searchParams.get("color");
    let maxPrice = searchParams.get("maxPrice");
    let minPrice = searchParams.get("minPrice");
    let condition = searchParams.get("condition");
    let minHeight = searchParams.get("minHeight");
    let maxHeight = searchParams.get("maxHeight");
    let minLength = searchParams.get("minLength");
    let maxLength = searchParams.get("maxLength");
    let minDepth = searchParams.get("minDepth");
    let maxDepth = searchParams.get("maxDepth");
    let sort = searchParams.get("sort");
    let tags = searchParams.get("tags");
    let op = searchParams.get("op");
    let room = searchParams.get("room");
    let design = searchParams.get("design");
    let designer = searchParams.get("designer");
    let product_features = searchParams.get("product_features");
    let keyword = searchParams.get("keyword");
    let discount = searchParams.get("discount");
    let resultsPerPage = 52;

    let lat = parseFloat(localStorage.getItem("lat"))
      ? parseFloat(localStorage.getItem("lat"))
      : 0;
    let lng = parseFloat(localStorage.getItem("long"))
      ? parseFloat(localStorage.getItem("long"))
      : 0;

    const index = await client.initIndex(
      !sort
        ? "kashew_listings"
        : sort == 1
        ? `kashew_listings_price_asc`
        : sort == 2
        ? `kashew_listings_price_desc`
        : sort == 3
        ? `kashew_listings_date_desc`
        : sort == 4
        ? `kashew_listings_date_asc`
        : sort == 5
        ? `kashew_listings_branded_deals`
        : sort == 6
        ? `kashew_listings_best_deals`
        : sort == 7
        ? `kashew_listings_closest`
        : sort == 0
        ? `kashew_listings`
        : `kashew_listings`
    );

    let beforeSearch = window.location.search;

    let searchObj = {
      hitsPerPage: resultsPerPage,
      clickAnalytics: true,
      page: page - 1,
      filters: `status:active${
        tags
          ? ` AND tags:"${tags.replace(/-/g, " ").replace(/\band\b/g, "&")}"`
          : ""
      }${
        color
          ? ` AND (velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        category
          ? ` AND newSchema.categories:"${category
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        subCategory
          ? ` AND newSchema.subCategories:"${subCategory
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        type
          ? ` AND newSchema.types:"${type
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        brand
          ? ` AND (brand:"${brand
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${location ? ` AND region:"${location}"` : ""}${
        style
          ? ` AND (velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        design
          ? ` AND (velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        designer
          ? ` AND (designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${designer
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        material
          ? ` AND (velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        product_features
          ? ` AND (velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(
                " ",
                "_"
              )}" OR velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        room
          ? ` AND (velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${minPrice ? ` AND price > ${minPrice}` : ""}${
        maxPrice ? ` AND price < ${maxPrice}` : ""
      }${minHeight ? ` AND measurements.H > ${minHeight}` : ""}${
        maxHeight ? ` AND measurements.H < ${maxHeight}` : ""
      }${minLength ? ` AND measurements.L > ${minLength}` : ""}${
        maxLength ? ` AND measurements.L < ${maxLength}` : ""
      }${minDepth ? ` AND measurements.D > ${minDepth}` : ""}${
        maxDepth ? ` AND measurements.D < ${maxDepth}` : ""
      }${discount ? ` AND discountPercentage > ${discount}` : ""}${
        condition
          ? ` AND condition:"${condition
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        op
          ? op === "designer"
            ? ` AND designerExists:true`
            : op === "branded"
            ? ` AND brandExists:1`
            : op === "top-picks"
            ? ` AND topPicks:true`
            : op === "iconic-designs"
            ? ` AND iconicDesign:true`
            : ""
          : ""
      }`,
      facets:
        "brand,velouAttributes.styles,velouAttributes.features,newBrand,region,velouAttributes.materials,velouAttributes.rooms,newSchema.categories,newSchema.subCategories,newSchema.types,velouAttributes.manufacturer,velouAttributes.brands",
    };
    console.log("searchObj", searchObj);

    let brandSearchFacetObj = {
      hitsPerPage: resultsPerPage,
      clickAnalytics: true,
      page: page - 1,
      filters: `status:active${
        tags
          ? ` AND tags:"${tags.replace(/-/g, " ").replace(/\band\b/g, "&")}"`
          : ""
      }${
        color
          ? ` AND (velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        category
          ? ` AND newSchema.categories:"${category
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        subCategory
          ? ` AND newSchema.subCategories:"${subCategory
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        type
          ? ` AND newSchema.types:"${type
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${location ? ` AND region:"${location}"` : ""}${
        style
          ? ` AND (velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        design
          ? ` AND (velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        designer
          ? ` AND (designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${designer
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        material
          ? ` AND (velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        product_features
          ? ` AND (velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(
                " ",
                "_"
              )}" OR velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        room
          ? ` AND (velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${minPrice ? ` AND price > ${minPrice}` : ""}${
        maxPrice ? ` AND price < ${maxPrice}` : ""
      }${minHeight ? ` AND measurements.H > ${minHeight}` : ""}${
        maxHeight ? ` AND measurements.H < ${maxHeight}` : ""
      }${minLength ? ` AND measurements.L > ${minLength}` : ""}${
        maxLength ? ` AND measurements.L < ${maxLength}` : ""
      }${minDepth ? ` AND measurements.D > ${minDepth}` : ""}${
        maxDepth ? ` AND measurements.D < ${maxDepth}` : ""
      }${discount ? ` AND discountPercentage > ${discount}` : ""}${
        condition
          ? ` AND condition:"${condition
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        op
          ? op === "designer"
            ? ` AND designerExists:true`
            : op === "branded"
            ? ` AND brandExists:1`
            : op === "top-picks"
            ? ` AND topPicks:true`
            : op === "iconic-designs"
            ? ` AND iconicDesign:true`
            : ""
          : ""
      }`,
      facets: "newBrand",
    };
    let styleSearchFacetObj = {
      hitsPerPage: resultsPerPage,
      clickAnalytics: true,
      page: page - 1,
      filters: `status:active${
        tags
          ? ` AND tags:"${tags.replace(/-/g, " ").replace(/\band\b/g, "&")}"`
          : ""
      }${
        color
          ? ` AND (velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        category
          ? ` AND newSchema.categories:"${category
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        subCategory
          ? ` AND newSchema.subCategories:"${subCategory
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        type
          ? ` AND newSchema.types:"${type
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        brand
          ? ` AND (brand:"${brand
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${location ? ` AND region:"${location}"` : ""}${
        design
          ? ` AND (velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        designer
          ? ` AND (designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${designer
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        material
          ? ` AND (velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        product_features
          ? ` AND (velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(
                " ",
                "_"
              )}" OR velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        room
          ? ` AND (velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${minPrice ? ` AND price > ${minPrice}` : ""}${
        maxPrice ? ` AND price < ${maxPrice}` : ""
      }${minHeight ? ` AND measurements.H > ${minHeight}` : ""}${
        maxHeight ? ` AND measurements.H < ${maxHeight}` : ""
      }${minLength ? ` AND measurements.L > ${minLength}` : ""}${
        maxLength ? ` AND measurements.L < ${maxLength}` : ""
      }${minDepth ? ` AND measurements.D > ${minDepth}` : ""}${
        maxDepth ? ` AND measurements.D < ${maxDepth}` : ""
      }${discount ? ` AND discountPercentage > ${discount}` : ""}${
        condition
          ? ` AND condition:"${condition
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        op
          ? op === "designer"
            ? ` AND designerExists:true`
            : op === "branded"
            ? ` AND brandExists:1`
            : op === "top-picks"
            ? ` AND topPicks:true`
            : op === "iconic-designs"
            ? ` AND iconicDesign:true`
            : ""
          : ""
      }`,
      facets: "velouAttributes.styles",
    };
    let materialSearchFacetObj = {
      hitsPerPage: resultsPerPage,
      clickAnalytics: true,
      page: page - 1,
      filters: `status:active${
        tags
          ? ` AND tags:"${tags.replace(/-/g, " ").replace(/\band\b/g, "&")}"`
          : ""
      }${
        color
          ? ` AND (velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        category
          ? ` AND newSchema.categories:"${category
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        subCategory
          ? ` AND newSchema.subCategories:"${subCategory
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        type
          ? ` AND newSchema.types:"${type
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        brand
          ? ` AND (brand:"${brand
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${location ? ` AND region:"${location}"` : ""}${
        style
          ? ` AND (velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        design
          ? ` AND (velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        designer
          ? ` AND (designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${designer
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        product_features
          ? ` AND (velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(
                " ",
                "_"
              )}" OR velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        room
          ? ` AND (velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${minPrice ? ` AND price > ${minPrice}` : ""}${
        maxPrice ? ` AND price < ${maxPrice}` : ""
      }${minHeight ? ` AND measurements.H > ${minHeight}` : ""}${
        maxHeight ? ` AND measurements.H < ${maxHeight}` : ""
      }${minLength ? ` AND measurements.L > ${minLength}` : ""}${
        maxLength ? ` AND measurements.L < ${maxLength}` : ""
      }${minDepth ? ` AND measurements.D > ${minDepth}` : ""}${
        maxDepth ? ` AND measurements.D < ${maxDepth}` : ""
      }${discount ? ` AND discountPercentage > ${discount}` : ""}${
        condition
          ? ` AND condition:"${condition
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        op
          ? op === "designer"
            ? ` AND designerExists:true`
            : op === "branded"
            ? ` AND brandExists:1`
            : op === "top-picks"
            ? ` AND topPicks:true`
            : op === "iconic-designs"
            ? ` AND iconicDesign:true`
            : ""
          : ""
      }`,
      facets: "velouAttributes.materials",
    };
    let locationSearchFacetObj = {
      hitsPerPage: 0,
      clickAnalytics: true,
      page: 0,
      filters: `status:active${
        tags
          ? ` AND tags:"${tags.replace(/-/g, " ").replace(/\band\b/g, "&")}"`
          : ""
      }${
        color
          ? ` AND (velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.product_color:"${color
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        category
          ? ` AND newSchema.categories:"${category
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        subCategory
          ? ` AND newSchema.subCategories:"${subCategory
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        type
          ? ` AND newSchema.types:"${type
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        brand
          ? ` AND (brand:"${brand
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.manufacturer:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${brand
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        style
          ? ` AND (velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.styles:"${style
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        design
          ? ` AND (velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.design:"${design
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        designer
          ? ` AND (designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.brands:"${designer
              .replace(/-/g, " ")
              .replace(
                /\band\b/g,
                "&"
              )}" OR velouAttributes.designer:"${designer
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}")`
          : ""
      }${
        material
          ? ` AND (velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.materials:"${material
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        product_features
          ? ` AND (velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(
                " ",
                "_"
              )}" OR velouAttributes.features:"${product_features
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${
        room
          ? ` AND (velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")
              .replace(" ", "_")}" OR velouAttributes.rooms:"${room
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}")`
          : ""
      }${minPrice ? ` AND price > ${minPrice}` : ""}${
        maxPrice ? ` AND price < ${maxPrice}` : ""
      }${minHeight ? ` AND measurements.H > ${minHeight}` : ""}${
        maxHeight ? ` AND measurements.H < ${maxHeight}` : ""
      }${minLength ? ` AND measurements.L > ${minLength}` : ""}${
        maxLength ? ` AND measurements.L < ${maxLength}` : ""
      }${minDepth ? ` AND measurements.D > ${minDepth}` : ""}${
        maxDepth ? ` AND measurements.D < ${maxDepth}` : ""
      }${discount ? ` AND discountPercentage > ${discount}` : ""}${
        condition
          ? ` AND condition:"${condition
              .replace(/-/g, " ")
              .replace(/\band\b/g, "&")}"`
          : ""
      }${
        op
          ? op === "designer"
            ? ` AND designerExists:true`
            : op === "branded"
            ? ` AND brandExists:1`
            : op === "top-picks"
            ? ` AND topPicks:true`
            : op === "iconic-designs"
            ? ` AND iconicDesign:true`
            : ""
          : ""
      }`,
      facets: "region",
    };

    if (lat && lng && sort == 7) {
      searchObj.aroundLatLng = `${lat},${lng}`;
      searchObj.aroundRadius = "all";
      searchObj.getRankingInfo = true;
    }

    const getSearchResults = async () => {
      let res = await index
        .search(keyword ? keyword : "", searchObj, {
          clickAnalytics: true,
          enablePersonalization: true,
          userToken: userToken ? userToken : null,
        })
        .then(async (res) => {
          if (res?.queryID) localStorage.setItem("queryID", res.queryID);
          let nowSearch = window.location.search;

          if (beforeSearch !== nowSearch) {
            return null;
          }

          return res;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      return res;
    };
    const getBrandFacets = async () => {
      if (brand) {
        let res = await index
          .search(keyword ? keyword : "", brandSearchFacetObj)
          .then(async (res) => {
            let nowSearch = window.location.search;

            if (beforeSearch !== nowSearch) {
              return null;
            }

            return res;
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
        return res;
      } else {
        return null;
      }
    };
    const getStyleFacets = async () => {
      if (style) {
        let res = await index
          .search(keyword ? keyword : "", styleSearchFacetObj)
          .then(async (res) => {
            let nowSearch = window.location.search;

            if (beforeSearch !== nowSearch) {
              return null;
            }

            return res;
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
        return res;
      } else {
        return null;
      }
    };
    const getMaterialFacets = async () => {
      if (material) {
        let res = await index
          .search(keyword ? keyword : "", materialSearchFacetObj)
          .then(async (res) => {
            let nowSearch = window.location.search;

            if (beforeSearch !== nowSearch) {
              return null;
            }

            return res;
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
        return res;
      } else {
        return null;
      }
    };
    const getLocationFacets = async () => {
      if (location) {
        let res = await index
          .search(keyword ? keyword : "", locationSearchFacetObj)
          .then(async (res) => {
            let nowSearch = window.location.search;

            if (beforeSearch !== nowSearch) {
              return null;
            }

            return res;
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
        return res;
      } else {
        return null;
      }
    };

    let [
      searchResults,
      brandFacets,
      styleFacets,
      materialFacets,
      locationFacets,
    ] = await Promise.all([
      getSearchResults(),
      getBrandFacets(),
      getStyleFacets(),
      getMaterialFacets(),
      getLocationFacets(),
    ]);

    if (searchResults) {
      dispatch(
        LISTINGS_SUCCESS({
          listings: searchResults.hits,
          paginationData: {
            totalListings: searchResults.nbHits,
            currentPage: searchResults.page + 1,
            totalPages: searchResults.nbPages,
            listingsPerPage: searchResults.hitsPerPage,
          },
          uniqueBrands: brandFacets
            ? brandFacets.facets.newBrand
              ? Object.entries(brandFacets.facets.newBrand).map(
                  ([name, count]) => ({
                    name,
                    count,
                  })
                )
              : []
            : searchResults.facets.newBrand
            ? Object.entries(searchResults.facets.newBrand).map(
                ([name, count]) => ({
                  name,
                  count,
                })
              )
            : [],
          uniqueRegions: locationFacets
            ? locationFacets.facets.region
              ? Object.entries(locationFacets.facets.region).map(
                  ([region, count]) => ({
                    region,
                    count,
                  })
                )
              : []
            : searchResults.facets.region
            ? Object.entries(searchResults.facets.region).map(
                ([region, count]) => ({
                  region,
                  count,
                })
              )
            : [],
          uniqueMaterials: materialFacets
            ? materialFacets.facets["velouAttributes.materials"]
            : searchResults.facets["velouAttributes.materials"],
          uniqueStyles: styleFacets
            ? styleFacets.facets["velouAttributes.styles"]
            : searchResults.facets["velouAttributes.styles"],
          uniqueRooms: searchResults.facets["velouAttributes.rooms"],
          uniqueCategories: searchResults.facets["newSchema.categories"]
            ? Object.entries(searchResults.facets["newSchema.categories"]).map(
                ([name, count]) => ({ name, count })
              )
            : [],
          uniqueSubCategories: searchResults.facets["newSchema.subCategories"]
            ? Object.entries(
                searchResults.facets["newSchema.subCategories"]
              ).map(([name, count]) => ({ name, count }))
            : [],
          uniqueTypes: searchResults.facets["newSchema.types"]
            ? Object.entries(searchResults.facets["newSchema.types"]).map(
                ([name, count]) => ({
                  name,
                  count,
                })
              )
            : [],
        })
      );
    } else {
      dispatch(LISTINGS_ERROR());
    }
  };

export const LOAD_ALL_NUMBERS = () => async (dispatch, getState) => {
  dispatch(NUMBERS_LOADING());

  const index = client.initIndex("kashew_listings");
  await index
    .search("", {
      hitsPerPage: 52,
      clickAnalytics: true,
      page: 0,
      facets:
        "brand,velouAttributes.styles,region,velouAttributes.materials,velouAttributes.rooms,newSchema.categories,newSchema.subCategories,newSchema.types,velouAttributes.manufacturer,velouAttributes.brands",
    })
    .then(async (res) => {
      console.log(res);
      dispatch(
        LOAD_NUMBERS_SUCCESS({
          paginationData: {
            totalListings: res.nbHits,
            currentPage: res.page + 1,
            totalPages: res.nbPages,
            listingsPerPage: res.hitsPerPage,
          },
          uniqueBrands: res.facets.brand
            ? Object.entries(res.facets.brand).map(([name, count]) => ({
                name,
                count,
              }))
            : [],
          uniqueRegions: res.facets.region
            ? Object.entries(res.facets.region).map(([region, count]) => ({
                region,
                count,
              }))
            : [],
          uniqueMaterials: res.facets["velouAttributes.materials"],
          uniqueStyles: res.facets["velouAttributes.styles"],
          uniqueRooms: res.facets["velouAttributes.rooms"],
          uniqueCategories: res.facets["newSchema.categories"]
            ? Object.entries(res.facets["newSchema.categories"]).map(
                ([name, count]) => ({ name, count })
              )
            : [],
          uniqueSubCategories: res.facets["newSchema.subCategories"]
            ? Object.entries(res.facets["newSchema.subCategories"]).map(
                ([name, count]) => ({ name, count })
              )
            : [],
          uniqueTypes: res.facets["newSchema.types"]
            ? Object.entries(res.facets["newSchema.types"]).map(
                ([name, count]) => ({ name, count })
              )
            : [],
        })
      );
    })
    .catch(async (error) => {
      console.log(error);
      dispatch(NUMBERS_ERROR());
    });
};

export default listings;
