import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const ArticleHelmet = () => {
	const { id } = useParams();
	const [article, setArticle] = useState();
	const [loading, setLoading] = useState(false);

	const fetchArticle = async () => {
		setLoading(true);
		await axios
			.get(`${process.env.REACT_APP_SERVER_API}/api/blogs/helmet/${id}`)
			.then((res) => {
				setArticle(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
		setLoading(false);
	};

	//Find Article
	useEffect(() => {
		fetchArticle();
	}, [id]);

	return (
		<>
			{article && !loading && (
				<Helmet>
					<title>{`${article.title}`}</title>
					<meta
						name="description"
						content={`${article.teaser ? article.teaser : article.title} `}
					/>

					<meta property="type" content="article" />
					<link
						rel="canonical"
						href={`https://www.kashew.com/blog/${article._id}`}
					/>

					<meta property="og:type" content="article" />
					<meta property="og:title" content={article.title} />
					<meta
						property="og:description"
						content={`${article.teaser ? article.teaser : article.title}`}
					/>

					<meta
						property="og:image"
						content={`${article.picture}?tr=w-300,h=158`}
					/>
					<meta property="og:image:width" content="300" />
					<meta property="og:image:height" content="158" />

					<meta property="og:image:alt" content={article.title} />
					<meta
						property="og:url"
						content={`https://www.kashew.com/blog/${article._id}`}
					/>
				</Helmet>
			)}
		</>
	);
};

export default ArticleHelmet;
