import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SET_ALERT } from './alert';

const initialState = {
	availableConsignments: {},
	availablePage: { current: 1, loaded: 1, total: 1 },

	acceptedConsignments: {},
	acceptedPage: { current: 1, loaded: 1, total: 1 },

	missedConsignments: {},
	missedPage: { current: 1, loaded: 1, total: 1 },

	searchConsignments: {},
	searchPage: { current: 1, loaded: 1, total: 1 },

	loading: false,
};

const consignments = createSlice({
	name: 'consignments',
	initialState,
	reducers: {
		SAVE_AVAILABLE_CONSIGNMENTS(state, action) {
			const { consignments, currentPage, totalPages } = action.payload;
			state.availableConsignments[currentPage] = consignments;
			state.availablePage.total = totalPages;
			state.availablePage.current = currentPage;
			if (currentPage > state.availablePage.loaded) {
				state.availablePage.loaded = currentPage;
			}
		},
		SAVE_ACCEPTED_CONSIGNMENTS(state, action) {
			const { consignments, currentPage, totalPages } = action.payload;
			state.acceptedConsignments[currentPage] = consignments;
			state.acceptedPage.total = totalPages;
			state.acceptedPage.current = currentPage;
			if (currentPage > state.acceptedPage.loaded) {
				state.acceptedPage.loaded = currentPage;
			}
		},
		SAVE_MISSED_CONSIGNMENTS(state, action) {
			const { consignments, currentPage, totalPages } = action.payload;
			state.missedConsignments[currentPage] = consignments;
			state.missedPage.total = totalPages;
			state.missedPage.current = currentPage;
			if (currentPage > state.missedPage.loaded) {
				state.missedPage.loaded = currentPage;
			}
		},
		SAVE_SEARCH_CONSIGNMENTS(state, action) {
			const { consignments, currentPage, totalPages } = action.payload;
			state.searchConsignments[currentPage] = consignments;
			state.searchPage.total = totalPages;
			state.searchPage.current = currentPage;
			if (currentPage > state.searchPage.loaded) {
				state.searchPage.loaded = currentPage;
			}
		},

		EMPTY_SEARCH_PRODUCTS(state, action) {
			state.searchConsignments = {};
			state.searchPage.total = 1;
			state.searchPage.current = 1;
		},

		SET_AVAILABLE_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.availablePage.current = currentPage;
			if (currentPage > state.availablePage.loaded) {
				state.availablePage.loaded = currentPage;
			}
		},
		SET_ACCEPTED_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.acceptedPage.current = currentPage;
			if (currentPage > state.acceptedPage.loaded) {
				state.acceptedPage.loaded = currentPage;
			}
		},
		SET_MISSED_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.missedPage.current = currentPage;
			if (currentPage > state.missedPage.loaded) {
				state.missedPage.loaded = currentPage;
			}
		},
		SET_SEARCH_PAGE(state, action) {
			const { currentPage } = action.payload;
			state.searchPage.current = currentPage;
			if (currentPage > state.searchPage.loaded) {
				state.searchPage.loaded = currentPage;
			}
		},
		SET_LOADING(state, action) {
			state.loading = action.payload;
		},
	},
});

export const LOAD_CONSIGNMENTS =
	({ type, page }) =>
	async (dispatch, getState) => {
		dispatch(SET_LOADING(true));
		await axios
			.get(
				`${process.env.REACT_APP_SERVER_API}/api/consign/get-consignments/${page}/${type}`
			)
			.then(async (res) => {
				const { paginationData, consignments } = res.data;
				if (type === 'available') {
					dispatch(
						SAVE_AVAILABLE_CONSIGNMENTS({
							consignments: consignments,
							currentPage: paginationData.currentPage,
							totalPages: paginationData.totalPages,
						})
					);
				} else if (type === 'accepted') {
					dispatch(
						SAVE_ACCEPTED_CONSIGNMENTS({
							consignments: consignments,
							currentPage: paginationData.currentPage,
							totalPages: paginationData.totalPages,
						})
					);
				} else if (type === 'missed') {
					dispatch(
						SAVE_MISSED_CONSIGNMENTS({
							consignments: consignments,
							currentPage: paginationData.currentPage,
							totalPages: paginationData.totalPages,
						})
					);
				} else {
					dispatch(
						SAVE_SEARCH_CONSIGNMENTS({
							consignments: consignments,
							currentPage: paginationData.currentPage,
							totalPages: paginationData.totalPages,
						})
					);
				}
				dispatch(SET_LOADING(false));
			})
			.catch(async (error) => {
				dispatch(
					SET_ALERT({
						msg: 'Error getting consignment',
						type: 'danger',
					})
				);
			});
		dispatch(SET_LOADING(false));
	};

export const {
	SAVE_AVAILABLE_CONSIGNMENTS,
	SAVE_ACCEPTED_CONSIGNMENTS,
	SAVE_MISSED_CONSIGNMENTS,
	SAVE_SEARCH_CONSIGNMENTS,
	EMPTY_SEARCH_PRODUCTS,
	SET_AVAILABLE_PAGE,
	SET_ACCEPTED_PAGE,
	SET_MISSED_PAGE,
	RESET_CONSIGNMENTS,
	SET_SEARCH_PAGE,
	SET_LOADING,
} = consignments.actions;

export default consignments;
