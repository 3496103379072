import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { locations } from "../../utils/locations";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const BuyPageHelmet = () => {
  const { search } = useLocation();

  const [option, setOption] = useState();
  const [brand, setBrand] = useState();
  const [material, setMaterial] = useState();
  const [style, setStyle] = useState();
  const [category, setCategory] = useState();
  const [subType, setSubType] = useState();

  const [keyword, setKeyword] = useState();
  const [location, setLocation] = useState();
  const [urlLoc, setUrlLoc] = useState();
  const [room, setRoom] = useState();

  const {
    categoryParam,
    optionParam,
    typeParam,
    brandParam,
    styleParam,
    locationParam,
    roomParam,
  } = useParams();

  // Extract URL QUERIES
  useEffect(() => {
    let loc = "";
    const params = new URLSearchParams(search);
    setKeyword(params.get("keyword"));
    setCategory(params.get("category") || categoryParam);
    setOption(params.get("option") || optionParam);
    setSubType(params.get("subType") || typeParam);

    setBrand(params.get("brand") || brandParam);
    setMaterial(params.get("material"));
    setStyle(params.get("style") || styleParam);
    setRoom(params.get("room") || roomParam);

    loc = params.get("location") || locationParam;
    if (loc) {
      let found = locations.find((i) => i.id.toLowerCase() === loc);
      if (found) {
        setLocation(found.geolocation);
        setUrlLoc(loc);
      } else {
        setLocation("");
        setUrlLoc("");
      }
    } else {
      setLocation("");
      setUrlLoc("");
    }
  }, [
    search,
    categoryParam,
    optionParam,
    typeParam,
    brandParam,
    styleParam,
    locationParam,
    roomParam,
  ]);

  const capitalizeEachWord = (text) => {
    const specialCases = {
      "1980_s": "1980s",
      "1990_s": "1990s",
      "20_th_century": "20th Century",
    };

    if (specialCases[text]) {
      return specialCases[text];
    }

    return text
      ? text
          .replace(/_/g, " ")
          .replace(/-/g, " ")
          .replace(/ and /g, " & ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "";
  };

  const generateTitle = (
    subType,
    brand,
    option,
    category,
    keyword,
    location,
    material,
    room,
    style
  ) => {
    let locationText = location ? ` in ${location} ` : "";

    let identifier =
      (material ? capitalizeEachWord(material) + " " : "") +
      (style ? capitalizeEachWord(style) + " " : "") +
      (room ? capitalizeEachWord(room) + " " : "");

    let descriptor = brand
      ? `Used ${capitalizeEachWord(brand)}` + " "
      : "Shop Used ";

    let subject = keyword
      ? `${capitalizeEachWord(keyword)} `
      : subType
      ? `${capitalizeEachWord(subType)} `
      : option
      ? `${capitalizeEachWord(option)} `
      : category
      ? `${capitalizeEachWord(category)} ${
          category === "seating" || category === "storage" ? "Furniture " : ""
        }`
      : "Furniture ";

    let ending = brand ? `– Up to 80% Off` : `- Up To 80% Off`;

    let title = `${descriptor}${identifier}${subject}${locationText}${ending}`;

    return title.trim(); // Removes any extra whitespace
  };

  const generateMetaDescription = (
    brand,
    category,
    subType,
    option,
    keyword,
    location,
    material,
    room,
    style
  ) => {
    let locationText = location ? ` in ${location} ` : " ";
    let identifier =
      (material ? capitalizeEachWord(material) + " " : "") +
      (style ? capitalizeEachWord(style) + " " : "") +
      (room ? capitalizeEachWord(room) + " " : "");

    let descriptor = brand
      ? `Shop Quality Used ${capitalizeEachWord(brand)} `
      : "Shop Used ";

    let subject = keyword
      ? `${capitalizeEachWord(keyword)}`
      : subType
      ? `${capitalizeEachWord(subType)}`
      : option
      ? `${capitalizeEachWord(option)}`
      : category
      ? `${capitalizeEachWord(category)} ${
          category === "Seating" || category === "Storage" ? "Furniture" : ""
        }`
      : "Furniture";

    let ending =
      "- Delivery available - no backorder. 100% Money-Back Guarantee. Explore our selections of gently used couches, tables, chairs, dressers and more.";
    let description = `${descriptor}${identifier}${subject}${locationText}${ending}`;

    return description.trim(); // Removes any extra whitespace
  };

  const createCanonicalURL = (params) => {
    // const queryParams = Object.entries(params)
    //   .filter(([key, value]) => Boolean(value))
    //   .map(
    //     ([key, value]) =>
    //       `${key}=${encodeURIComponent(value).replace(/%20/g, "+")}`
    //   )
    //   .join("&");

    // return queryParams
    //   ? `https://www.kashew.com/buy?${queryParams}`
    //   : `https://www.kashew.com/buy`;
    return `${window.location.origin}${window.location.pathname}${window.location.search}`;
  };

  const params = {
    brand,
    subType,
    category,
    option,
    keyword,
    location: urlLoc,
    material,
    room,
    style,
  };

  const canonicalURL = createCanonicalURL(params);

  return (
    <>
      <Helmet>
        <title>
          {generateTitle(
            subType,
            brand,
            option,
            category,
            keyword,
            location,
            material,
            room,
            style
          )}
        </title>
        <link rel="canonical" href={canonicalURL} />

        <meta
          property="og:title"
          content={generateTitle(
            subType,
            brand,
            option,
            category,
            keyword,
            location,
            material,
            room,
            style
          )}
        />
        <meta
          name="description"
          content={generateMetaDescription(
            brand,
            category,
            subType,
            option,
            keyword,
            location,
            material,
            room,
            style
          )}
        />
        <meta
          property="og:description"
          content={generateMetaDescription(
            brand,
            category,
            subType,
            option,
            keyword,
            location,
            material,
            room,
            style
          )}
        />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/kuj9zqlrx0or/Static/ogImg.png"
        />
        <meta
          property="og:image:alt"
          content="Kashew Buy & Sell Second Hand Furniture"
        />
        <meta property="og:url" content={canonicalURL} />
        <meta
          property="og:site_name"
          content="Kashew - Buy and Sell Second Hand Furniture"
        />
      </Helmet>
    </>
  );
};

export default BuyPageHelmet;
