export const locations = [
	{
		id: 'BA',
		area: 'Bay Area, California',
		geolocation: 'San Francisco',
		lat: '37.828724',
		long: '-122.355537',
		postcode: '94103',
	},
	{
		id: 'PHO',
		area: 'Phoenix, Arizona',
		geolocation: 'Phoenix',
		lat: '33.448376',
		long: '-112.074036',
		postcode: '85004 ',
	},
	{
		id: 'CHI',
		area: 'Chicago, Illinois',
		geolocation: 'Chicago',
		lat: '41.881832',
		long: '-87.623177',
		postcode: '60601',
	},
	{
		id: 'MIA',
		area: 'Miami, Florida',
		geolocation: 'Miami',
		lat: '25.761681',
		long: '-80.191788',
		postcode: '33130',
	},
	{
		id: 'HOU',
		area: 'Houston, Texas',
		geolocation: 'Houston',
		lat: '29.743278',
		long: '-95.369234',
		postcode: '77002',
	},
	{
		id: 'NY',
		area: 'New York City, New York',
		geolocation: 'New York',
		lat: '40.71427',
		long: '-74.00597',
		postcode: '10001',
	},
	{
		id: 'PDX',
		area: 'Portland, Oregon',
		geolocation: 'Portland',
		lat: '45.522739',
		long: '-122.673770',
		postcode: '97209',
	},
	{
		id: 'COS',
		area: 'Colorado Springs, Colorado',
		geolocation: 'Colorado Springs',
		lat: '38.831732',
		long: '-104.820758',
		postcode: '80903',
	},
	{
		id: 'DTX',
		area: 'Dallas, Texas',
		geolocation: 'Dallas',
		lat: '32.776123',
		long: '-96.79218',
		postcode: '75201',
	},
	{
		id: 'ATL',
		area: 'Atlanta, Georgia',
		geolocation: 'Atlanta',
		lat: '33.753573',
		long: '-84.386840',
		postcode: '30303',
	},
	{
		id: 'LA',
		area: 'Los Angeles, California',
		geolocation: 'LA',
		lat: '34.044549',
		long: '-118.253537',
		postcode: '90014',
	},
	{
		id: 'PHL',
		area: 'Philadelphia, Pennsylvania',
		geolocation: 'Philadelphia',
		lat: '39.952872',
		long: '-75.161109',
		postcode: '19107',
	},
	{
		id: 'FM',
		area: 'Fort Myers, Florida',
		geolocation: 'Fort Myers',
		lat: '26.625676',
		long: '-81.841768',
		postcode: '33916',
	},
	{
		id: 'ICT',
		area: 'Wichita, Kansas',
		geolocation: 'Wichita',
		lat: '37.687770',
		long: '-97.329884',
		postcode: '67208',
	},
	{
		id: 'RN',
		area: 'Reno, Nevada',
		geolocation: 'Reno',
		lat: '39.530659',
		long: '-119.816773',
		postcode: '89501',
	},
	{
		id: 'ME',
		area: 'Augusta, Maine',
		geolocation: 'Augusta',
		lat: '44.309142',
		long: '-69.782473',
		postcode: '04973',
	},
	{
		id: 'VA',
		area: 'Arlington, Virginia',
		geolocation: 'Arlington',
		lat: '38.895059',
		long: '-77.077536',
		postcode: '22209',
	},
	{
		id: 'MA',
		area: 'Plymouth, Massachusetts',
		geolocation: 'Plymouth',
		lat: '41.947525',
		long: '-70.665510',
		postcode: '02360',
	},
];
