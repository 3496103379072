import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const CustomHelmet = () => {
	const { id } = useParams();
	const [listing, setListing] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getListing = async () => {
			setLoading(true);
			await axios
				.get(`${process.env.REACT_APP_SERVER_API}/api/listings/helmet/${id}`)
				.then(async (res) => {
					setListing(res.data);
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getListing();
	}, [id]);

	return (
		<>
			{listing && !loading && (
				<Helmet>
					<title>{`${listing.title} - Kashew`}</title>
					<meta
						name="description"
						content={`${listing.title} ${
							listing.brand
								? `- from ${listing.brand} `
								: listing.velouAttributes?.manufacturer?.length > 0
								? listing.velouAttributes?.manufacturer[0]
										?.replace(/_/g, ' ')
										?.split(' ')
										?.map(
											(word) => word.charAt(0).toUpperCase() + word.slice(1)
										)
										?.join(' ')
								: listing.velouAttributes?.brands?.length > 0
								? listing.velouAttributes?.brands[0]
										?.replace(/_/g, ' ')
										?.split(' ')
										?.map(
											(word) => word.charAt(0).toUpperCase() + word.slice(1)
										)
										?.join(' ')
								: ''
						}| only $${listing.price} | Second Hand Furniture - ${
							listing.newSchema.subCategories[0]
								? listing.newSchema.subCategories[0]
								: ''
						} ${
							listing.newSchema.types[0]
								? ' - ' + listing.newSchema.types[0]
								: ''
						} - delivery available & money-back guarantee`}
					/>
					<meta
						name="keywords"
						content={`${
							listing.newSchema.categories[0]
								? listing.newSchema.categories[0]
								: ''
						}, ${
							listing.newSchema.subCategories[0]
								? listing.newSchema.subCategories[0]
								: ''
						}, ${
							listing.newSchema.types[0]
								? listing.newSchema.types[0] + ', '
								: ''
						}${
							listing.brand
								? `${listing.brand}`
								: listing.velouAttributes?.manufacturer?.length > 0
								? listing.velouAttributes?.manufacturer[0]
										?.replace(/_/g, ' ')
										?.split(' ')
										?.map(
											(word) => word.charAt(0).toUpperCase() + word.slice(1)
										)
										?.join(' ')
								: listing.velouAttributes?.brands?.length > 0
								? listing.velouAttributes?.brands[0]
										?.replace(/_/g, ' ')
										?.split(' ')
										?.map(
											(word) => word.charAt(0).toUpperCase() + word.slice(1)
										)
										?.join(' ')
								: ''
						}`}
					/>
					<meta property="type" content="product" />
					<link
						rel="canonical"
						href={`https://www.kashew.com/product/${
							listing.kashewId
						}/${listing.title
							.toLowerCase()
							.replace(/[_\W]+/g, '-')
							.replace(/^-+/, '')
							.replace(/-+$/, '')}`}
					/>
					<meta property="og:type" content="product" />
					<meta property="og:title" content={`${listing.title} - Kashew`} />
					<meta
						property="og:description"
						content={`${listing.title} ${
							listing.brand ? `- from ${listing.brand} ` : ''
						}| only $${listing.price} | Second Hand Furniture - ${
							listing.newSchema.subCategories[0]
								? listing.newSchema.subCategories[0]
								: ''
						} ${
							listing.newSchema.types[0]
								? ' - ' + listing.newSchema.types[0]
								: ''
						} - delivery available & money-back guarantee`}
					/>
					<meta
						property="og:image"
						content={`${listing.images[0]}?tr=w-300,h=158`}
					/>
					<meta property="og:image:width" content="300" />
					<meta property="og:image:height" content="158" />
					<meta
						property="og:image:alt"
						content={`${
							listing.brand
								? listing.brand
								: listing.velouAttributes?.manufacturer?.length > 0
								? listing.velouAttributes?.manufacturer[0]
										?.replace(/_/g, ' ')
										?.split(' ')
										?.map(
											(word) => word.charAt(0).toUpperCase() + word.slice(1)
										)
										?.join(' ')
								: listing.velouAttributes?.brands?.length > 0
								? listing.velouAttributes?.brands[0]
										?.replace(/_/g, ' ')
										?.split(' ')
										?.map(
											(word) => word.charAt(0).toUpperCase() + word.slice(1)
										)
										?.join(' ')
								: ''
						} ${listing.title}`}
					/>
					<meta
						property="og:url"
						content={`https://www.kashew.com/product/${
							listing.kashewId
						}/${listing.title
							.toLowerCase()
							.replace(/[_\W]+/g, '-')
							.replace(/^-+/, '')
							.replace(/-+$/, '')}`}
					/>

					<meta
						name="theme-color"
						content={
							listing.colors.length > 0 &&
							listing.colors[0].slice(0, 3) === 'rgb'
								? listing.colors[0]
								: '#632340'
						}
					/>
				</Helmet>
			)}
		</>
	);
};

export default CustomHelmet;
